import { reaction } from "mobx";
import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useChatContext } from "../../services/contexts/chat-context";
import styled from 'styled-components';
import {
    ChatUrgentMessageContainer, ChatUrgentMessage, ChatUrgentMessageTextContainer, ChatUrgentMessageText, ChatUrgentMessageAlert, ChatUrgentMessageAlertImg,
    ChatFooterContainer, ChatFooterText, ChatFooterMessageAlert,
    ChatFooterMessage, ChatFooterMessageTextContainer, ChatFooterMessageText,ChatFooterMessageAlertImg
} from "./common.component";
import { CHAT_APP_ID } from "../../types/global";
import { UrgentMessagePopup } from "../help/urgent-message-popup.component";

const OutOfHoursContainer = styled.div`
    background: ${(props: any) => props.theme.colors.mainWindowBackground};
    text-align: center;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    width:100%;
    height:100%;
    position: absolute;
`;

const OutOfHoursHeader = styled.div`
    background: ${(props: any) => props.theme.colors.mainWindowBackground};
    color: ${(props: any) => props.theme.colors.secondary};
`;

const OutOfHoursHeaderTextContainer = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 48px;
    background: ${(props: any) => props.theme.colors.primaryHeader};
    color: ${(props: any) => props.theme.colors.secondary};
    align-items: center;
    display: flex;
    
`;

const OutOfHoursHeaderText = styled.div`
    text-align: center;
    width: 100%;
    letter-spacing: 0.98px;
`;

const OutOfHoursTitleContainer = styled.div<{ text: string | undefined, isDisabled: boolean | undefined }>`
    margin-top: ${(props: any) => (props.text && props.text !== "") && !props.isDisabled ? '11px' : '39px'};
`;

const OutOfHoursTitle = styled.h1`
    margin: -9px 0 0 0;
    font-weight: normal;
    line-height: 29px;
    padding-bottom: 4px;
    font-size: 16px;
    letter-spacing: 1.12px;
    color: ${(props: any) => props.theme.colors.primary};
`;

const OutOfHoursAmanda = styled.img<{ text: string | undefined, isDisabled: boolean | undefined }>`
    margin-left: 18px;
    margin-top: ${(props: any) => (props.text && props.text !== "") && !props.isDisabled ? '9px' : (props.text && props.text !== "") || !props.isDisabled ? '21px' : '73px'};
`;

const OutOfHoursBody = styled.div`
    background: ${(props: any) => props.theme.colors.mainWindowBackground};
`;

const OutOfHoursChatUnavailable = styled.div<{ text: string | undefined, isDisabled: boolean | undefined }>`
    padding: 0 15px ${(props: any) => (props.text && props.text !== "") && !props.isDisabled ? '22px' : '45px'};
    text-align: center;
    color: ${(props: any) => props.theme.colors.secondary};
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1.12px;
`;

const OutOfHoursChatHours = styled.div<{ hours: string, text: string | undefined, isDisabled: boolean | undefined}>`
    background: ${(props: any) => props.theme.colors.primaryHeader}; 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 0 52px;
    padding: 15px 20px;
    color: #888; //${(props: any) => props.theme.colors.secondary};
    font-size: 14px;
    letter-spacing: 0.98px;
    display: ${(props: any) => !props.isDisabled ? 'block' : 'none'};
    font-weight: normal;

     > div {
        color: ${(props: any) => props.theme.colors.secondary};
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: ${(props: any) => props.hours.length > 13 ? '14px' : '28px'};
        letter-spacing: ${(props: any) => props.hours.length > 13 ? '0.98px' : '1.96px'};
        padding: 10px 0;
      }
`;

const OutOfHoursChatclosed = styled.div<{ text: string | undefined, isDisabled: boolean | undefined }>`
    text-align: center;
    color: ${(props: any) => props.theme.colors.secondary};
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.98px;
    padding: 20px 35px 10px;

    > div {
        opacity: .5;

        &:nth-child(3){
            padding-top: 15px;
        }

        &:nth-last-child(2){
            padding-bottom: ${(props: any) => (props.text && props.text !== "") && !props.isDisabled ? '22px' : '45px'};
        }
    }

    > a {
        border: 2px solid ${(props: any) => props.theme.colors.primary};
        border-radius: 30px;
        letter-spacing: 0.98px;
        text-decoration: none;
        color: ${(props: any) => props.theme.colors.primary};
        padding: 7px 0;
        opacity: 1;
        margin-top: 10px;
        width: 100%;
        display: block;

        &:active{
            background: #000;
        }

        &:focus{
            outline: none;
        }
    }
`;

// const OutOfHoursFooterContainer = styled(ChatFooterContainer)`
//     display: ${(props: any) => props.text && props.text !== "" ? 'block' : 'none'};
//     margin: 20px 15px 10px;
//     bottom: 0;
// `;
const OutOfHoursFooterContainer = styled.div`
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-size: 10px !important;
`;


const OutOfHours: React.FC<any> = () => {

    const services = useChatContext().services;
    const settings = services.chatData.chatSettings;
    const chatHours = services.chatData.chatHours;
    const languageService =  services.languageService;

    // const hours = !chatHours?.isDisabled && chatHours?.outsideOfWorkingHoursText && chatHours?.outsideOfWorkingHoursText !== null 
    //     ? chatHours.outsideOfWorkingHoursText.substring(chatHours.outsideOfWorkingHoursText.indexOf("<div>") + 5, 
    //         chatHours.outsideOfWorkingHoursText.indexOf("</div>")) : "";
   
    // const zone = !chatHours?.isDisabled && chatHours?.outsideOfWorkingHoursText && chatHours?.outsideOfWorkingHoursText !== null ?
    //              chatHours.outsideOfWorkingHoursText.substring(chatHours.outsideOfWorkingHoursText.indexOf("</div>") + 6, 
    //              chatHours.outsideOfWorkingHoursText.length) : "";
    
    const hours = !chatHours?.isDisabled && chatHours?.openHours ? chatHours?.openHours : "";
    const zone = !chatHours?.isDisabled && chatHours?.openHours ? chatHours?.openHoursTimezone: ""; 
    const hoursMessage = languageService.translate('ChatAvailable_Between');

    services.messageBroker.initSucceded(CHAT_APP_ID);

    services.logger.info("Rendering Out of hours component @chatHours, @hours, @settings ", { chatHours: chatHours, hours: hours, settings: settings});

    const [isUrgentMessageTextExpanded, setIsUrgentMessageTextExpanded] = useState(false);

    const closeUrgentMessagePopup = () => {
        setIsUrgentMessageTextExpanded(false);
    }

    const onUrgentMessageClick = (isLargeText: boolean) => {
        if(isLargeText){
            setIsUrgentMessageTextExpanded(true);
        }
    }

    let bannerText = chatHours?.urgentMessageText;
    let isLargeText = false;
    if(chatHours?.urgentMessageText)
    {
        const characterLimit = 80;

        isLargeText = chatHours?.urgentMessageText.length > characterLimit;
    
        if(isLargeText) {
            const lastCharacterPosition = chatHours?.urgentMessageText.indexOf(' ', characterLimit) === -1 ? 
                                            chatHours?.urgentMessageText.length :
                                            chatHours?.urgentMessageText.indexOf(' ', characterLimit)
            bannerText = chatHours?.urgentMessageText.substring(0, lastCharacterPosition) + "...";
        }
    }

    return (
        <div>
            {
                isUrgentMessageTextExpanded && <UrgentMessagePopup onClose={closeUrgentMessagePopup}></UrgentMessagePopup>
            }
            <OutOfHoursContainer id="rn_PageContent">
                <OutOfHoursHeader>
                    <OutOfHoursHeaderTextContainer>
                        <OutOfHoursHeaderText>{settings?.headerText}</OutOfHoursHeaderText>
                    </OutOfHoursHeaderTextContainer>
                    <ChatUrgentMessageContainer text={chatHours?.urgentMessageText}>
                        <ChatUrgentMessage id="urgent_message" text={bannerText} isLargeText={isLargeText} onClick={() => onUrgentMessageClick(isLargeText)}>
                            <ChatUrgentMessageTextContainer>
                                <ChatUrgentMessageText dangerouslySetInnerHTML={{ __html: (bannerText ? bannerText : "") }}></ChatUrgentMessageText>
                            </ChatUrgentMessageTextContainer>
                            <ChatUrgentMessageAlert className="alert"><ChatUrgentMessageAlertImg src={services.themes.currentTheme.assets.images.urgent_message_icon_svg} alt="alert icon"></ChatUrgentMessageAlertImg></ChatUrgentMessageAlert>
                        </ChatUrgentMessage>
                    </ChatUrgentMessageContainer>
                    <OutOfHoursAmanda src={services.themes.currentTheme.assets.images.amanda_help_sleeping_2x_png} text={chatHours?.urgentMessageText} isDisabled={chatHours?.isDisabled} />
                    <OutOfHoursTitleContainer id="rn_PageTitle" text={chatHours?.urgentMessageText} isDisabled={chatHours?.isDisabled}>
                        <OutOfHoursTitle dangerouslySetInnerHTML={{ __html: settings?.pageTitle ? settings.pageTitle : "" }} />
                    </OutOfHoursTitleContainer>
                </OutOfHoursHeader>
                <OutOfHoursBody id="oohBody">
                    <OutOfHoursChatUnavailable text={chatHours?.urgentMessageText} isDisabled={chatHours?.isDisabled} dir={settings?.chatHoursEntry ? (settings.chatHoursEntry?.indexOf("arabic") > -1 ? 'rtl' : 'initial') : 'initial'} dangerouslySetInnerHTML={{ __html: (settings?.closedTitle ? settings.closedTitle : "") }}>
                    </OutOfHoursChatUnavailable>
                    <OutOfHoursChatHours id="oohChatHours" hours={hours} 
                        dir={settings?.chatHoursEntry ? (settings.chatHoursEntry?.indexOf("arabic") > -1 ? 'rtl' : 'initial') : 'initial'}
                        text={chatHours?.urgentMessageText} isDisabled={chatHours?.isDisabled}
                        //  dangerouslySetInnerHTML={{ __html: (!chatHours?.isDisabled && chatHours?.outsideOfWorkingHoursText && chatHours?.outsideOfWorkingHoursText !== null ? 
                        //      chatHours?.outsideOfWorkingHoursText : "") }}>
                        >{hoursMessage}
                        <div>{hours}</div>
                        {zone}
                    </OutOfHoursChatHours>
                    <OutOfHoursChatclosed id="oohChatClosed" dir={settings?.chatHoursEntry ? (settings.chatHoursEntry?.indexOf("arabic") > -1 ? 'rtl' : 'initial') : 'initial'} dangerouslySetInnerHTML={{ __html: (settings?.closed && settings?.closed !== null ? settings?.closed : "") }}
                        text={chatHours?.urgentMessageText} isDisabled={chatHours?.isDisabled}>
                    </OutOfHoursChatclosed>
                </OutOfHoursBody>
                {/* <OutOfHoursFooterContainer text={settings?.footertext}>
                    <ChatFooterText id="footer_text" dangerouslySetInnerHTML={{ __html: (settings?.footertext ? settings.footertext : "") }}></ChatFooterText>
                </OutOfHoursFooterContainer> */}
                    <OutOfHoursFooterContainer>
                        <ChatFooterMessage id="footer_text" text={settings?.footertext}>
                            <ChatFooterMessageTextContainer>
                                <ChatFooterMessageAlert link={settings?.rgLink} className="">
                                <ChatFooterMessageAlertImg 
                                    src={services.resourceService.resolveResourcePath('/images/RG_logo.png')} 
                                    alt="responsible gaming"
                                    textLength={settings?.footertext?.length}>
                                </ChatFooterMessageAlertImg>
                                </ChatFooterMessageAlert>
                                <ChatFooterMessageText link={settings?.rgLink}
                                    dangerouslySetInnerHTML={{ __html: (settings?.footertext ? settings.footertext : "") }}>
                                </ChatFooterMessageText>
                            </ChatFooterMessageTextContainer>

                        </ChatFooterMessage>
                    </OutOfHoursFooterContainer>
            </OutOfHoursContainer>
        </div>
    )
}



export default OutOfHours;
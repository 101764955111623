import styled from 'styled-components';

export const ChatUrgentMessageContainer = styled.div<{ text: string | undefined }>`
    padding-bottom: 10px;
    text-align: left;
    height: 40px;
    display: ${(props: any) => props.text && props.text !== "" ? 'block' : 'none'};
`;

export const ChatUrgentMessage = styled.div<{ text: string | undefined , isLargeText: boolean}>`
    margin-top: -10px;
    background-color: ${(props: any) => props.theme.colors.chatPageUrgentMessageBackground};
    display: ${(props: any) => props.text && props.text !== "" ? 'flex' : 'none'};
    cursor: ${(props: any) => props.isLargeText ? "pointer" : "auto"};
    border-top: 1px solid #4C4C4C;
`;

export const ChatUrgentMessageTextContainer = styled.div`
    float: left;
    width: 100%;
`;

export const ChatUrgentMessageText = styled.div`
    position: relative;
    line-height: 14px;
    height: 40px;
    font-size: 12px;
    font-weight: normal;
    width: 84%;
    margin-left: 14%;
    margin-right: 2%;
    padding-top: 6px;
    color: ${(props: any) => props.theme.colors.secondaryText};

    > a {
        color: ${(props: any) => props.theme.colors.secondaryText};
        text-decoration: underline;

        &:hover, &:active{
            color: ${(props: any) => props.theme.colors.secondaryText};
        }
        
        &:visited {
            color: ${(props: any) => props.theme.colors.secondaryText};
        }
    }
`;

export const ChatUrgentMessageAlert = styled.div`
    width: 14%;
    float: left;
    height: 40px;
    margin-left: -100%;
`;

export const ChatUrgentMessageAlertImg = styled.img`
    position: relative;
    margin-top: 8px;
    margin-left: 28%;
    width: 24px;
    height: 24px;
`;

export const ChatFooterContainer = styled.div<{ text: string | undefined }>`
    background: #171717;
    padding: 10px 15px;
    margin: 10px 15px 22px;
    border-radius: 10px;
`;

export const ChatFooterContainerText = styled(ChatFooterContainer)<{logoSource: string | undefined }>`
    display: ${(props: any) => props.text && props.text !== "" ? 'block' : 'none'}
`;

export const ChatFooterText = styled.div`
    font-size: 10px;
    color: #717171;
    letter-spacing: 0.7px;
    text-align: left;
`;

export const ChatFooterMessage = styled.div<{ text: string | undefined }>`
   margin: 0px 10px 5px;
    
    display: ${(props: any) => props.text && props.text !== "" ? 'flex' : 'none'};
`;

export const ChatFooterMessageTextContainer = styled.div`
    float: left;
    width: 100%;
    display: inline-flex;
`;

export const ChatFooterMessageText = styled.div<{link: string | undefined}>`
    position: relative;
    background: #171717;
    padding: 10px 20px 10px 10px;
    line-height: 1.2;
    font-weight: normal;
    // margin-left: ${(props: any) => props.link && props.link != "" ? '62px' : '0'};
    // border-radius: ${(props: any) => props.link && props.link != "" ? '0 10px 10px 0' : '10px 10px 10px 10px'};
    border-radius: '0 10px 10px 0' ;
    font-size: 10px;
    color: #717171;
    letter-spacing: 0.7px;
    text-align: center;
    min-height: 0 !important;

    > a {
         display: inline-flex;
         color: color: #0077EE;
         &:hover, &:active{
            color: #0077EE;
        }
        
        &:visited {
            color: #8F30CB;
        }
        
`;

export const ChatFooterMessageAlert = styled.div<{link: string | undefined}>`
    width: 67%;
    float: left;
    background: #0d0d0d;
    border-radius: 10px 0 0 10px;
    // margin-left: -100%;
    //display: ${(props: any) => props.link && props.link !== "" ? 'flex' : 'none'} ;  
    display: flex;  
    //cursor: pointer;
    text-align: center;
    height: auto;
    align-items: center;
    justify-content: center;
    min-height: 0 !important;
`;

export const ChatFooterMessageAlertImg = styled.img<{textLength: number | undefined}>`
    position: relative;
    width: 32px;
    height: 32px;
    //top: ${(props: any) => props.textLength && props.textLength > 215 ? '22px': '19px'};
    display: inline-flex;
`;

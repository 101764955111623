import { ServiceBase } from "./service-base";
import { IServiceFactory } from "./service-factory.interface";
import config from "../config.json"

export interface IResourcesService {
    resolveResourcePath(fileName: string): string;
}

export class ResourcesService extends ServiceBase implements IResourcesService {
    constructor(services: IServiceFactory){
        super(services)
    }

    resolveResourcePath(fileName: string): string{
        const url = new URL(this.services.chatData.environmentSettings.resourcesOrigin)
        url.pathname = `${fileName}`;
        // console.log(url);
        return url.href;
        //return ""
    }
}
import { PlayerChannel } from "@sparkware/uc-sdk-core/lib/message-broker/channels/player/player.channel";
import { action, makeObservable, observable, runInAction } from "mobx";
import { textChangeRangeIsUnchanged } from "typescript";
import Axios from "../utils/AxiosInstance";
import { isNotSet } from "../utils/utility";
import { PlayerData } from "./chat-data/chat-data.service.interface";
import { ServiceBase } from "./service-base";
import { IServiceBase } from "./service-base.interface";
import { IServiceFactory } from "./service-factory.interface";

export interface IPlayerService extends IServiceBase {
  playerData?: PlayerData;

  isAuthenticated: boolean;

  authPlayer(token: string): Promise<boolean>;
}

export class PlayerService extends ServiceBase implements IPlayerService {
  constructor(serviceFactory: IServiceFactory) {
    super(serviceFactory);
    makeObservable(this, {
      playerData: observable,
      isAuthenticated: observable.ref,

      authPlayer: action.bound,
    });
  }

  playerData?: PlayerData | undefined;
  isAuthenticated: boolean = false;

  async authPlayer(token: string): Promise<boolean> {
    if (isNotSet(token)) {
      return false;
    }

    this.services.logger.info("Load player data start @token", {
      token: token,
    });
    const response = await Axios(this.services)
      .post(
        this.environmentSettings.apiUrl + "player/get-data",
        {
          token: token,
        } //,headers
      )
      .catch((e) => {
        throw new Error(`Could not load player data with error: ${e}`);
      });

    this.services.logger.info("Load player data response", response);
    this.playerData = {
      cid: response.data.playerId,
      email: response.data.email,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      userName: response.data.userName,
      playerValue: response.data.playerValue,
      playerValueId: response.data.playerValueID,
      clientTypeId: response.data.clientTypeID,
      currentClientVersion: response.data.currentClientVersion,
      countryISO3: response.data.countryISO3,
      countryId: response.data.countryId,
      registrationCountry: response.data.registrationCountry,
      isUk: response.data.isUk,
    };

    this.isAuthenticated = true;

    return true;
  }
}

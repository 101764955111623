import {
  AppIdentifiers,
  ComponentID,
  ITopicHeaders,
} from "@sparkware/uc-sdk-core";

export interface IUserContext {
  sessionId?: string;
  scriptId?: string;
  userInfo?: string;
  brandId?: number;
  subBrandId?: number;
  productPackage?: number;
  serverId?: number;
  systemId?: number;
  lang?: string;
  locale?: string;
  clientPlatform?: number;
  clientVersion?: string;
}

export interface IWidgetOptions {
  context: IUserContext;
  onClose?: Function;
  onCallToAction?: Function;
  onError?: Function;
}

export const CHAT_APP_ID: AppIdentifiers = AppIdentifiers.CRChat;
export const CHAT_BUBBLE_APP_ID: AppIdentifiers = AppIdentifiers.CRChatBubble;

export const CHAT_TOPIC_HEADERCHAT_APP_ID: ITopicHeaders = {
  publisher: CHAT_APP_ID,
};

export const CHAT_BUBBLE_TOPIC_HEADERS: ITopicHeaders = {
  publisher: CHAT_BUBBLE_APP_ID,
};

export type LogLevel = "Error" | "Debug" | "Info" | "Warning";

export enum AnalyticsEventType {
  AppClosed,
  AppMini,
  AppInit,
  OpenApp,
  MiniEvent,
}

export enum ChatAvatarType {
  agent = "/images/Amanda@2x.png",
  bot = "/images/Amanda@2x.png",
}

export const ORACLE_SDK_SCRIPT: string = "oracleSdk";
export const LOTTIE_PLAYER_SCRIPT: string = "lottie-player";
export const CHAT_CONTAINER: string = "chatbot_window";

import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { WsopMurphyMainScalePalette } from "./wsop-murphy-main-scale-palette";
import { defaultTheme } from "../default/default-theme";
import { WsopMainScalePalette } from "../wsop/wsop-main-scale-palette";


export const wsopMurphyTheme= {

    colors: { ...defaultTheme.colors,
        primary: WsopMurphyMainScalePalette.Primary,
        secondary:  DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.DarkGray,
        outOfHoursContainerText: DefaultGreyScalePalette.White,
        outOfHoursContainerBackground: WsopMurphyMainScalePalette.Tertiary,
        outOfHoursBackground: WsopMurphyMainScalePalette.Quaternary,
        urgentMessageText: "#888888",
        urgentMessageBackground: WsopMurphyMainScalePalette.Quinary,
        primaryButtonText: DefaultGreyScalePalette.White,
        primaryButtonBackground: WsopMurphyMainScalePalette.Primary,
        primaryButtonBackgroundHover: "#C86976",
        livePersonComposerTextBackgroundColor: DefaultGreyScalePalette.DarkGray,
        livePersonComposerText: DefaultGreyScalePalette.White,
        livePersonChatBackgroundColor: WsopMurphyMainScalePalette.Tertiary,
        livePersonAgentBackgroundMessageColor: WsopMurphyMainScalePalette.Secondary,
        livePersonAgentMessageColor: DefaultGreyScalePalette.White,
        livePersonVisitorBackgroundMessageColor: WsopMurphyMainScalePalette.Quaternary,
        livePersonVistorMessageBorderColor: WsopMurphyMainScalePalette.Quinary,
        livePersonVisitorMessageColor: DefaultGreyScalePalette.White,
        livePersonTimestampColor: DefaultGreyScalePalette.White,
        livePersonNotificationColor: "#D969696",
        livePersonInputText: DefaultGreyScalePalette.White,
        livePersonChipsColor: DefaultGreyScalePalette.White,
        livePersonChipsHoverColor: DefaultGreyScalePalette.White,
        livePersonChipsBorderColor: DefaultGreyScalePalette.White,
        livePersonSendButtonColor: DefaultGreyScalePalette.White,
        iconColors: { ...defaultTheme.colors.iconColors,
            default: DefaultGreyScalePalette.White,
            disabledPrimary: DefaultGreyScalePalette.White,
            disabledSecondary: "#1d1d1d",
            hover: DefaultGreyScalePalette.White,
            openLinkIcon: DefaultGreyScalePalette.White,
            faqSpacingLine: DefaultGreyScalePalette.White
        } as IIconColors,
        helpCenterMainPageColors: {
            greetingMessage: DefaultGreyScalePalette.White,
            userName: WsopMurphyMainScalePalette.Primary,
            faqTitle: DefaultGreyScalePalette.White, 
        } as IHelpCenterMainPageColors
    } as GeneralColors,
    images: {
        sendButton: 'Send Button PA',
        liveChatIcon: 'Live Chat Icon Dublin',
        minimizedIcon: 'Minimized Icon Dublin',
        minimizedNewMessageIcon: 'Minimized New Message Icon Dublin',
        amandaBubble: "Ava Bubble Not",
        amandaBubbleWithNotification: "Ava Bubble" 
    },
    textKeys: {
        ...defaultTheme.textKeys,   
        greetingMessage: "WSOP_Murphy_Greeting_Message",
        chatButtonLabel: "AllCaps_Chat_Button_Label",
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        faqButtonLabel: "AllCaps_Faq_Button_Label",
        hiMessage: ""
    },  
    fontWeight: {
        normal: '600',
        weights: {
            greetingMessage: '500',
            faqTitle: '600',
            faqQuestion: '400',
            outOfHoursCloseButton: '900',
            outOfHoursText: '700',
            outOfHoursHours: '700',
            outOfHoursCountry: '600',
            urgentMessageText: '600',
            urgentMessageCloseButton: '900',
            livePersonAgentMessage: '400'
        },
        opacity: {
            outOfHoursText: '0.5',
            versionNumber: '0.4'
        },
        padding: {
            outOfHoursHours: "0px"
        }
    }
};
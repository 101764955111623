import "./common/webpack-public-path";
import { configure as configureMobx } from "mobx";
import { ChatWidgetAppController } from "./sdk/chat-widget.controller";
import { IChatWidgetAppController } from "./sdk/chat-widget.controller.interface";
import { ChatBubblerController } from "./sdk/chat-bubble.controller";
import { IChatBubbleControler } from "./sdk/chat-bubble.controller.interface";
import { IHelpWidgetAppController } from "./sdk/help-widget.controller.interface";
import { HelpWidgetAppController } from "./sdk/help-widget.controller";
import {
  ILivePersonChatState,
  LivePersonChatState,
} from "./services/live-person-chat-data/live-person-state.service";

// import(services.resourceService.resolveResourcePath('/images/' + chatSettings?.sendButtonIcon + '.png')).then(image => {
//     setSendButton(image.default)
// });

// const validationError =  validationErrorFactory('ChatWidget')
configureMobx({ isolateGlobalState: true });

interface LPModel {
  cid: string;
  authServiceApi: string;
  
}

declare global {
  interface Window {
    chatTesting?: boolean;
    CR_CHAT_BUBBLE: IChatBubbleControler;
    token: string;
    //Bots: any;
    CR_CHAT: IChatWidgetAppController;
    //serviceFactory: ServiceFactory;
    CR_HELP_CENTER: IHelpWidgetAppController;
    lpTag: ILivePersonChatState;
    lpGetToken: object;
    location: Location;

    // lpModel: LPModel; 
    // lpGetLivePersonAuthCode: (cid: string) => Promise<string>;
  }
}

//window.lpTag=window.lpTag||{},'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'22574045'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;

if (!window.chatTesting) {
  window.CR_CHAT = new ChatWidgetAppController();

  // add the bubble also
  window.CR_CHAT_BUBBLE = new ChatBubblerController(document);

  window.CR_HELP_CENTER = new HelpWidgetAppController();
  const chat = new ChatWidgetAppController();
} else {
  // chat.init({
  //     applicationContext: {
  //         brandID: 0,
  //         appID: CHAT_APP_ID,
  //         currencyCode: "",
  //         languageCode: "en",
  //         launchInfo: {
  //             openingMode: "rightSideBar",
  //             trigger: "userSelection",
  //             containerID: "container"
  //         },
  //         locale: "",
  //         productPackageFromID: 1,
  //         subBrandID: -1
  //     },
  //     messageBrokerChannels: new MessageBroker({
  //         debug: () => {},
  //         error: () => {},
  //         info: () => {},
  //         warning: () => {}
  //     })
  //     ,
  //     deviceInfo: {
  //         deviceType: 3,
  //         isHybrid: false
  //     },
  //     geoInfo: {
  //         country: "",
  //         timeZoneUTCOffset: ""
  //     },
  //     userInfo: {
  //         userMode: "authenticated",
  //         regulationTypeID: 1,
  //         //token: window.token
  //         token: "Token_PFRva2VuQmxvYkZvckF1dGhlbnRpY2F0aW9uIEF1dGhlbnRpY2F0aW9uQmxvY2s9IjY3ZmYzMjM1LTc2ODctNGQwYS1iN2UwLTFlODNjYmZiYTlmNyIgRW5jcnlwdGlvblR5cGU9IjUiPg0KICA8VG9rZW5Tb3VyY2U+Q2lkPC9Ub2tlblNvdXJjZT4NCjwvVG9rZW5CbG9iRm9yQXV0aGVudGljYXRpb24+"
  //     }
  // });
  // const serviceFactory = new ServiceFactory(new MessageBroker({
  //     debug:() => {},
  //     warning:() => {},
  //     info:() => {},
  //     error: () => {}
  // }));
  // interface RootComponentState {
  //     theme: ChatTheme;
  // }
  // ReactDOM.render(
  //     <React.StrictMode>
  //         <ChatContextProvider value={{services: serviceFactory}}>
  //             <RootComponent/>
  //         </ChatContextProvider>
  //     </React.StrictMode>,
  //     document.getElementById('root')
  // );
}

// export function init(initData: unknown) {
//     const appInitData = validateInitData(initData);

//     switch (appInitData.applicationContext.appID) {
//         case 'CHAT_WIDGET':
//             const chat = new ChatWidgetAppController();
//             chat.init(validateDivInitData(initData));
//             break;
//         case 'ONLINE_BANKING_IFRAME_WIDGET':

//             break;
//         default:
//             break;
//     }
// }

// function validateBaseData<T>(data: IChatInitParameters): void {
//     if (isNotSet(data)) throw new Error('Missing all required parameters');
//     let returnData = data as IChatInitParameters;
//     if (isNotSet(returnData.applicationContext)) throw validationError('Missing required parameter: applicationContext');
//     if (isNotSet(returnData.deviceInfo)) throw validationError('Missing required parameter: deviceInfo');
//     if (isNotSet(returnData.geoInfo)) throw validationError('Missing required parameter: geoInfo');
//     if (isNotSet(returnData.userInfo)) throw validationError('Missing required parameter: userInfo');
//     if (isNotSet(returnData.applicationContext.appID)) throw validationError('Missing required parameter: applicationContext.appID');
//     if (isNotSet(returnData.applicationContext.launchInfo))
//         throw validationError('Missing required parameter: applicationContext.launchInfo');
//     if (isNotSet(returnData.applicationContext.launchInfo.containerID))
//         throw validationError('Missing required parameter: applicationContext.launchInfo.containerID');
// }

// function validateInitData<T>(data: any): IInitObject<T> {
//     if (isNotSet(data)) throw new Error('Missing all required parameters');
//     let returnData = data as IInitObject<T>;
//     validateBaseData(returnData);
//     return returnData;
// }

// function validateZoidInitData<T, V>(data: any): IZoidInitObject<T, V> {
//     let returnData = data as IZoidInitObject<T, V>;
//     validateInitData(data);
//     if (isNotSet(returnData.performAction)) throw validationError('Missing required parameter: performAction');
//     if (isNotSet(returnData.onInitSucceeded)) throw validationError('Missing required parameter: onInitSucceeded');
//     if (isNotSet(returnData.onInitFailed)) throw validationError('Missing required parameter: onInitFailed');
//     return returnData;
// }

// function validateDivInitData<T>(data: any): IDivInitObject<T> {
//     let returnData = data as IDivInitObject<T>;
//     validateInitData(data);
//     if (isNotSet(returnData.messageBrokerChannels)) throw validationError('Missing required parameter: messageBrokerChannels');
//     return returnData;
// }

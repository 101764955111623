import React from "react";
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const QuestionMarkSVG: FC = observer(() => {
    const {
        themes
    } = useChatContext().services;

    return (
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="FAQ">
        <path id="Path 2823" d="M2.95905 1.8661H26.309V1.86311C26.6662 1.83755 27.019 1.95308 27.2919 2.18495C27.5647 2.41682 27.7357 2.74652 27.768 3.1031V22.2261C27.7357 22.5827 27.5647 22.9123 27.2919 23.1442C27.019 23.3761 26.6662 23.4916 26.309 23.4661H12.0211C10.6361 24.4761 9.05604 26.9871 8.04504 27.6871C7.68486 27.9561 7.25297 28.1124 6.80402 28.1361C6.69207 28.1481 6.57888 28.1354 6.47241 28.0988C6.36594 28.0622 6.26883 28.0026 6.18799 27.9243C6.10714 27.8459 6.04455 27.7507 6.00464 27.6454C5.96473 27.5401 5.94846 27.4273 5.95703 27.3151C5.95703 26.7361 5.94003 24.3741 5.92303 23.4651H2.95905C2.60191 23.4907 2.24896 23.3751 1.97614 23.1432C1.70331 22.9113 1.53235 22.5817 1.5 22.2251V3.10609C1.53235 2.74951 1.70331 2.41987 1.97614 2.188C2.24896 1.95613 2.60191 1.84054 2.95905 1.8661Z" fill={themes.currentTheme.colors.questionMarkBackground} stroke={themes.currentTheme.colors.primary} stroke-width="2"/>
        <g id="Group 42556">
        <path id="Path 2821" d="M14.3331 15.7455L14.3425 15.7457L14.3519 15.7456C14.4187 15.7446 14.4849 15.7572 14.5467 15.7826C14.6085 15.808 14.6645 15.8456 14.7113 15.8932L14.7219 15.904L14.7331 15.9141C14.7759 15.9527 14.8101 15.9998 14.8336 16.0526C14.8571 16.1054 14.8692 16.1625 14.8692 16.2201C14.8692 16.2778 14.8571 16.3349 14.8336 16.3876C14.8101 16.4404 14.7759 16.4876 14.7331 16.5262L14.7248 16.5337L14.7168 16.5415C14.6689 16.5888 14.6119 16.6261 14.5493 16.6512C14.4867 16.6763 14.4198 16.6887 14.3524 16.6877L14.3424 16.6875L14.3323 16.6878C14.1895 16.6913 14.051 16.6385 13.9467 16.5408L13.9468 16.5408L13.9397 16.5344C13.8963 16.4952 13.8619 16.4474 13.8387 16.394C13.8154 16.3406 13.8038 16.2828 13.8047 16.2244L13.8048 16.2244L13.8048 16.2157C13.8047 16.1571 13.8168 16.0993 13.8403 16.046C13.8638 15.9926 13.8983 15.9446 13.9416 15.9052L13.9474 15.8999L13.953 15.8944C14.0033 15.8456 14.0628 15.8073 14.128 15.7817C14.1932 15.7562 14.2629 15.7439 14.3331 15.7455Z" fill="#047C4C" stroke={themes.currentTheme.colors.primary}/>
        <path id="Path 2822" d="M14.027 13.1653L14.0279 13.1505V13.1356V11.8687V11.8502L14.0265 11.8318C14.0214 11.7622 14.0435 11.6932 14.0882 11.6394C14.1248 11.5953 14.1742 11.5643 14.2291 11.5503C14.4556 11.5663 14.6833 11.5371 14.8989 11.4642C15.1383 11.3832 15.357 11.2503 15.5391 11.0749C15.7212 10.8996 15.8623 10.6861 15.9522 10.4499C16.0416 10.2152 16.0783 9.96381 16.0598 9.71341C16.047 9.4967 15.9911 9.28471 15.8954 9.08978C15.799 8.89328 15.6641 8.71817 15.4988 8.57478C15.3335 8.43138 15.1411 8.32257 14.933 8.25483C14.7249 8.1871 14.5053 8.16182 14.2873 8.18047L14.2535 8.18336L14.2204 8.19079C13.5287 8.34608 12.9107 8.73239 12.4682 9.28576C12.4331 9.3214 12.3863 9.34352 12.3363 9.34802C12.2829 9.35281 12.2296 9.33717 12.1871 9.30409L12.1872 9.30401L12.1792 9.29802L12.169 9.2904C12.0753 9.2113 12.0156 9.09899 12.0027 8.97684C11.9894 8.85181 12.0261 8.72662 12.1048 8.62863L12.1134 8.61793L12.1214 8.60676C12.3768 8.2502 12.707 7.95372 13.0888 7.73804C13.4645 7.52586 13.8814 7.39691 14.311 7.35982C14.8517 7.3677 15.374 7.55891 15.7922 7.90245C16.2157 8.25044 16.5067 8.73351 16.6162 9.27064C16.7256 9.80779 16.647 10.3663 16.3935 10.8523C16.1414 11.3353 15.7318 11.7176 15.2326 11.9357C15.0531 12.0061 14.8991 12.1296 14.7917 12.2901C14.6832 12.4521 14.6274 12.6437 14.6319 12.8385V13.1367V13.1511L14.6327 13.1656C14.6383 13.2608 14.6059 13.3542 14.5428 13.4256C14.4871 13.4886 14.4114 13.5299 14.3291 13.543C14.247 13.5295 14.1717 13.4881 14.1163 13.4251C14.0535 13.3536 14.0214 13.2602 14.027 13.1653Z" fill="#047C4C" stroke={themes.currentTheme.colors.primary}/>
        </g>
        </g>
        </svg>
        
    );
})

import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const EmailIconHoverSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.845"
      height="11.942"
      viewBox="0 0 16.845 11.942"
    >
      <g
        id="email_default_state"
        data-name="email default state"
        transform="translate(0.25 0.25)"
      >
        <path
          id="Path_2816"
          data-name="Path 2816"
          d="M1056.069,915.969a.349.349,0,0,1,.362.334v9.344a.349.349,0,0,1-.362.333h-14.135a.349.349,0,0,1-.362-.333V916.3a.349.349,0,0,1,.362-.334h14.135m0-.715h-14.135a1.08,1.08,0,0,0-1.1,1.049v9.344a1.08,1.08,0,0,0,1.1,1.049h14.135a1.08,1.08,0,0,0,1.1-1.049V916.3a1.08,1.08,0,0,0-1.1-1.049Z"
          transform="translate(-1040.829 -915.254)"
          fill={themes.currentTheme.colors.iconColors.hover}
          stroke={themes.currentTheme.colors.iconColors.hover}
          stroke-width="0.5"
        />
        <path
          id="Path_2817"
          data-name="Path 2817"
          d="M1049.011,923.841a.359.359,0,0,1-.258-.108l-7.078-7.147a.37.37,0,0,1,0-.522.363.363,0,0,1,.517,0l6.819,6.886,6.866-6.932a.363.363,0,0,1,.517,0,.371.371,0,0,1,0,.522l-7.125,7.193A.359.359,0,0,1,1049.011,923.841Z"
          transform="translate(-1040.833 -915.258)"
          fill={themes.currentTheme.colors.iconColors.hover}
          stroke={themes.currentTheme.colors.iconColors.hover}
          stroke-width="0.5"
        />
        <path
          id="Path_2818"
          data-name="Path 2818"
          d="M1059.914,928.976a.488.488,0,0,1-.372-.168l-3.906-3.989a.482.482,0,0,1,.052-.685.5.5,0,0,1,.694.052l3.906,3.989a.482.482,0,0,1-.052.685A.5.5,0,0,1,1059.914,928.976Z"
          transform="translate(-1044.852 -918.296)"
          fill={themes.currentTheme.colors.iconColors.hover}
          stroke={themes.currentTheme.colors.iconColors.hover}
          stroke-width="0.5"
        />
        <path
          id="Path_2819"
          data-name="Path 2819"
          d="M1042.132,928.948a.528.528,0,0,1-.324-.11.463.463,0,0,1-.067-.676l3.74-4.261a.528.528,0,0,1,.714-.064.461.461,0,0,1,.067.676l-3.74,4.261A.519.519,0,0,1,1042.132,928.948Z"
          transform="translate(-1040.834 -918.268)"
          fill={themes.currentTheme.colors.iconColors.hover}
          stroke={themes.currentTheme.colors.iconColors.hover}
          stroke-width="0.5"
        />
      </g>
    </svg>
  );
});

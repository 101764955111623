import React from 'react'
import { ThemeProvider } from "styled-components";
import { useChatContext } from "../../services/contexts/chat-context";
import { Help } from "./help.component";
import { observer } from 'mobx-react-lite';
import { LoadingSpinner } from '../ui-elements/loading-spinner.component';
import { GlobalStyle } from '../../theming/global-styles';

export const HelpRoot: React.FC = observer(() => {

    const {
        themes,
        applicationService
    } = useChatContext().services;

    function renderAppContent () {
        return  (
            <Help />
        ) 
    }

    function renderLoader() {
        return (
            <LoadingSpinner/>
        )
    }
    return (
        <ThemeProvider theme={themes.currentTheme}>
            <GlobalStyle/>
            {
                applicationService.isLoading ?
                    renderLoader() :
                    renderAppContent()
            }
        </ThemeProvider>
    )
})
import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { useChatContext } from "../../services/contexts/chat-context";
import { observer } from "mobx-react-lite";
import { OpenLinkIconSVG } from "../../resources/SVG/open-link-icon";
import {
  FilterItem,
  OsvcFaqReport,
} from "../../services/osvc/osvc.service.interface";
import { ActionID, AppIdentifiers } from "@sparkware/uc-sdk-core";
import { FAQ_REPORT_LIMIT, OSVC_ENGLISH_CODE } from "../../enums/faq-report";

const FaqReportContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 85%;
  color: ${(props: any) => props.theme.colors.secondary};
`;

const FaqTitle = styled.span.attrs((props: any) => ({id:props.id}))`
  font-family: ${(props: any) => props.theme.fontFamily};
  font-weight: ${(props: any) => props.theme.fontWeight.weights.faqTitle};
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.helpCenterMainPageColors.faqTitle};
  margin-left: 3px;
`;

const FaqLinksContainer = styled.div`
  margin-top: 8px;
  width: 100%;
  height: 254px;
  background-color: ${(props: any) =>
    props.theme.colors.faqUrgentMessageBackground};
  border-radius: 10px;
  padding-top: 1px;
`;

const FaqLinkPlaceholder = styled.div<{ isOdd: boolean }>`
  width: 94%;
  height: 38px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 3%;
  margin-right: 3%;
  background-color: ${(props: any) => props.theme.colors.secondaryHeader};
  opacity: ${(props: any) => (props.isOdd ? 1 : 0.5)};
`;

const FaqText = styled.span`
  font-family: ${(props: any) => props.theme.fontFamily};
  font-weight: ${(props: any) => props.theme.fontWeight.weights.faqQuestion} !important;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors.secondaryText};
`;

const FaqQuestion = styled.div<{ isFirst: boolean; isLast: boolean }>`
  width: 95%;
  height: 20px;
  cursor: pointer;
  padding: 15px 20px;
  background-color: ${(props: any) =>
    props.theme.colors.faqUrgentMessageBackground};
  border-radius: ${(props: any) => (props.isFirst ? "10px" : "0")} 0 0
    ${(props: any) => (props.isLast ? "10px" : "0")};

  > span {
    font-weight: normal;
  }
`;

const SpacingLine = styled.hr`
  width: 90%;
  display: block;
  border: 0;
  border-top: 1px solid ${(props: any) => props.theme.colors.iconColors.faqSpacingLine};
  padding: 0;
  margin: 0px;
  margin-left: auto;
  margin-right: auto;
`;

const FaqSVGContainer = styled.div<{ isFirst: boolean; isLast: boolean }>`
  width: 9%;
  height: 20px;
  background-color: ${(props: any) =>
    props.theme.colors.faqUrgentMessageBackground};
  border-radius: 0 ${(props: any) => (props.isFirst ? "10px" : "0")}
    ${(props: any) => (props.isLast ? "10px" : "0")} 0;
  cursor: pointer;
  padding-top: 17px;
`;

const FaqReportBody = styled.div`
  display: flex;
  height: 50px;
`;

const RetryText = styled.div`
  font-family: ${(props: any) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-top: 88px;
  width: 100%;
  text-align: center;
  color: ${(props: any) => props.theme.colors.secondaryText};
`;

const RetryButton = styled.button`
  margin-top: 80px;
  width: 86%;
  height: 40px;
  margin-left: 7%;
  margin-right: 7%;
  font-weight: 700;
  font-size: 14px;
  border-radius: 20px;
  color: ${(props: any) => props.theme.colors.primaryButtonText};
  background-color: ${(props: any) => props.theme.colors.primaryButtonBackground};
  border: 2px solid;
  border-color: ${(props: any) => props.theme.colors.primaryButtonBackground};
  cursor: pointer;
  font-family: ${(props: any) => props.theme.fontFamily};

  :hover {
    color: ${(props: any) => props.theme.colors.primaryButtonText};
    background-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
    border-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};

  }

  :active {
    color: ${(props: any) => props.theme.colors.primaryButtonText};
    background-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
    border-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
  }
`;

export const FaqReport: React.FC = observer(() => {
  const services = useChatContext().services;

  const openFaq = (url: string) => {
    services.messageBroker.performAction(
      AppIdentifiers.CRChat,
      ActionID.openLink,
      undefined,
      {
        url: url,
        openExternally: true,
      }
    );
  };

  const _renderLoader = () => {
    return (
      <FaqReportContainer id="faqReportContainer">
        <FaqTitle id="faqTitle">
          {services.languageService.translate("PopularFAQs_Title")}
        </FaqTitle>
        <FaqLinksContainer>
          <FaqLinkPlaceholder isOdd={true} />
          <FaqLinkPlaceholder isOdd={false} />
          <FaqLinkPlaceholder isOdd={true} />
          <FaqLinkPlaceholder isOdd={false} />
          <FaqLinkPlaceholder isOdd={true} />
        </FaqLinksContainer>
      </FaqReportContainer>
    );
  };

  const tryAgain = () => {
    services?.chatData?.runOsvcReport(
      services?.chatData?.chatSettings,
      services?.chatData?.channel
    );

    return;
  };

  function _renderAppContent() {
    return (
      <FaqReportContainer id="faqReportContainer">
        <FaqTitle id ="faqTitle">
          {services.languageService.translate("PopularFAQs_Title")}
        </FaqTitle>
        {(osvcService.reportStatus.hasError ||
          osvcService.reportResult.length < FAQ_REPORT_LIMIT) && (
          <FaqLinksContainer>
            <RetryText>
              {services.languageService.translate("PopularFAQs_ErrorPage")}
            </RetryText>
            <RetryButton onClick={() => tryAgain()}>
              {services.languageService.translate("PopularFAQs_ErrorButton")}
            </RetryButton>
          </FaqLinksContainer>
        )}
        {!osvcService.reportStatus.hasError &&
          osvcService.reportResult.length >= FAQ_REPORT_LIMIT && (
            <FaqLinksContainer>
              {osvcService.reportResult?.map(
                (item: OsvcFaqReport, index: number) => (
                  <div key={index}>
                    <FaqReportBody id="faqReportBody">
                      <FaqQuestion
                        isFirst={index == 0}
                        isLast={FAQ_REPORT_LIMIT - 1 == index}
                        id={`faqQuestion_${index.toString()}`}
                        onClick={() => openFaq(item.answerUrl)}
                      >
                        <FaqText>{item.summary}</FaqText>
                      </FaqQuestion>
                      <FaqSVGContainer
                        id="faqSVG"
                        isFirst={index == 0}
                        isLast={FAQ_REPORT_LIMIT - 1 == index}
                        onClick={() => openFaq(item.answerUrl)}
                      >
                        <OpenLinkIconSVG />
                      </FaqSVGContainer>
                    </FaqReportBody>
                    {FAQ_REPORT_LIMIT - 1 != index && <SpacingLine id="spacingLine"/>}
                  </div>
                )
              )}
            </FaqLinksContainer>
          )}
      </FaqReportContainer>
    );
  }
  const { themes, applicationService, osvcService } = useChatContext().services;

  return (
    <ThemeProvider theme={themes.currentTheme}>
      {osvcService.reportStatus.hasExecuted
        ? _renderAppContent()
        : _renderLoader()}
    </ThemeProvider>
  );
});

import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { WsopMainScalePalette } from "./wsop-main-scale-palette";
import { defaultTheme } from "../default/default-theme";

export const wsopTheme = {
    colors: {
        ...defaultTheme.colors,
        primary: WsopMainScalePalette.Primary,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.Dark,
        outOfHoursContainerText: WsopMainScalePalette.Primary,
        primaryButtonText: DefaultGreyScalePalette.White,
        primaryButtonBackground: WsopMainScalePalette.Primary,
        primaryButtonBackgroundHover: "#C86976",
        livePersonChipsColor : WsopMainScalePalette.Primary,
        livePersonChipsBorderColor: WsopMainScalePalette.Primary,
        livePersonSendButtonColor: WsopMainScalePalette.Primary,
        iconColors: {
            ...defaultTheme.colors.iconColors
        } as IIconColors,
        helpCenterMainPageColors: {
            ...defaultTheme.colors.helpCenterMainPageColors,
            userName: WsopMainScalePalette.Primary,
        } as IHelpCenterMainPageColors
    } as GeneralColors,
    images: {
        ...defaultTheme.images,
        sendButton: 'Send Button PA',
        liveChatIcon: 'Live Chat Icon Dublin',
        minimizedIcon: 'Minimized Icon Dublin',
        minimizedNewMessageIcon: 'Minimized New Message Icon Dublin',

        amandaBubble: "Amanda WSOP Bubble 55x55px",
        amandaBubbleWithNotification: "Amanda WSOP Bubble Not 55x55px"
    },
    textKeys: {
        ...defaultTheme.textKeys,  
        faqButtonLabel: "AllCaps_Faq_Button_Label",
        chatButtonLabel: "Chat_Button_Label", 
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        noLabel: "AllCaps_No_Label",
        yesLabel: "AllCaps_Yes_Label"
    },
};

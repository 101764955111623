import axios from 'axios'
import { Check } from "@sparkware/app-utils";
import { IServiceFactory } from '../services/service-factory.interface';


const Axios = (services: IServiceFactory) => {
    const axiosInstance = axios.create();
    const { appInitParameters } = services.applicationService;
    console.log(`Correlation Id: ${appInitParameters?.applicationContext.launchInfo.businessCorrelationID}`);
    axiosInstance
        .interceptors
        .request
        .use(
            config => {
                //config.headers = { 'X-CorrelationId': LocalStorageService.getCorrelationId() };
                config.headers = { 
                    'X-CorrelationId': 
                     Check.isEmpty(appInitParameters?.applicationContext.launchInfo.businessCorrelationID)? 
                            services.applicationService.sequentialCorrelationID:
                            appInitParameters?.applicationContext.launchInfo.businessCorrelationID};
                return config;
            }, 
            error => {
                return Promise.reject(error);
            }
        );
    return axiosInstance
}

export default Axios;
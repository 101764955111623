import { ILogger } from "@sparkware/uc-sdk-core/lib/logger/logger.interface";
import { IReactionDisposer } from "mobx";
import { getConfig } from "../config";
import { CHAT_APP_ID } from "../types/global";
import { isNotSet, validationErrorFactory } from "../utils/utility";
import { EnvironmentSettings } from "./chat-data/chat-data.service.interface";
import { WidgetPublisher } from "./message-broker/message-broker.interface";
import { IServiceBase } from "./service-base.interface";
import { IServiceFactory } from "./service-factory.interface";

const validation =  validationErrorFactory('ServiceBase');

export class ServiceBase {
    
    constructor(protected readonly services: IServiceFactory){

    }
    
    get logger(): ILogger {
        return this.services.logger;
    }
   
    dispose(){
        this.reactionDisposers.forEach(element => {
            element(); //
        });
    }

    throwAppError(error: string) {
        this.services.messageBroker.appError(CHAT_APP_ID);
    }

    get environmentSettings(): EnvironmentSettings {
        // return {
        //     apiUrl: "",
        //     odaUri: "",
        //     resourcesOrigin: ""
        // };
       return getConfig();
    }

    protected reactionDisposers: IReactionDisposer[] = [];
    
}


import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const MinimizeButtonSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg width="22" height="10" viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 3L20.5 3C21.3284 3 22 2.32843 22 1.5C22 0.671573 21.3284 -5.87108e-08 20.5 -1.31134e-07L1.5 -1.79217e-06C0.671574 -1.86459e-06 2.03558e-07 0.671571 1.31134e-07 1.5C5.87108e-08 2.32843 0.671574 3 1.5 3Z" fill={themes.currentTheme.colors.headerButtonsColor}/>
    </svg>
  );
});




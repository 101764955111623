import { Check } from "@sparkware/app-utils";
import {IServiceBase} from './service-base.interface';
import {ServiceFactory} from './service-factory';

export class Lazy<T extends IServiceBase>{
    constructor(private _valueFactory: () => T) {
    }

    private _value: T = undefined!;
    private _initializing = false;
    private _initValue() {
        if(this._initializing) {
            throw new Error('Lazy circular dependency detected: ' + this._valueFactory)
        }
        if(Check.isNullOrUndefined(this._value)) {
            this._initializing = true;
            try {
                this._value = this._valueFactory();
                ServiceFactory.loadedServices.push(this._value);
            }
            finally {
                this._initializing = false;
            }

        }
    }

    get value(): T {
        this._initValue();

        return this._value;
    }

    forceInit() {
        this._initValue();
    }
}


import { IBrand } from "./brand.interface";


export class Brand implements IBrand {
    constructor(id: number | undefined, name: string | undefined){
        this.id = id;
        this.name = name;
    }
    id: number | undefined;
    name: string | undefined;
    
    get displayName(): string{
        switch(this.name){
            case '777':
                return '777';
            default:
                return '888';
        }
    }
}
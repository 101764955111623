
import { action, makeObservable, observable, runInAction } from "mobx";
import { ServiceBase } from "../service-base";
import { IServiceBase } from "../service-base.interface";
import { IServiceFactory } from "../service-factory.interface";
import { TranslationWithParams } from "./translation-with-params";
import  AR from './translations/texts/texts.ar-AR.json';
import  DK from './translations/texts/texts.da-DK.json';
import  DE from './translations/texts/texts.de-DE.json';
import  GB from './translations/texts/texts.en-GB.json';
import  US from './translations/texts/texts.en-US.json';
import  ES from './translations/texts/texts.es-ES.json';
import  PT from './translations/texts/texts.pt-PT.json';
import  RO from './translations/texts/texts.ro-RO.json';
import  SE from './translations/texts/texts.sv-SE.json';
import  CA from './translations/texts/texts.en-CA.json';
import  IT from './translations/texts/texts.it-IT.json';


export interface ILanguageService extends IServiceBase {
    setCurrentLanguage(lang: string):void;

    currentLanguage: string;
    translate(template:string): string;
    translationFor( template: string ): TranslationWithParams;
}

export const DEFAULT_LANGUAGE = "en-GB"

export class LanguageService extends ServiceBase implements ILanguageService{

    constructor(services: IServiceFactory) {
        super(services)

        this.currentLanguage = DEFAULT_LANGUAGE;

        makeObservable(this, {
            currentLanguage: observable.ref,
            _texts: observable.ref,
            setCurrentLanguage: action.bound
        })
    }

    currentLanguage: string;
    _texts: Record<string, string> = {}; 

    get texts(): Record<string, string> {
        return this._texts;
    }

    setCurrentLanguage(language: string): void {
        this.currentLanguage = language;
        this._loadTexts(language);
    }

    private _loadTexts(languageCode: string){
        
        // if(languageCode === DEFAULT_LANGUAGE){
        //     this._setTexts({})
        //     return;
        // }
        console.log(`LanguageCode: ${languageCode}`)
        // if(languageCode === DEFAULT_LANGUAGE){
        //     this._setTexts({})
        //     return;
        // }
        // const path =  this.services.resourceService.resolveResourcePath('./translations/texts/texts.${languageCode}.ts');
        // console.log(path);
        
        try{
            if(languageCode.indexOf("ar-") !== -1){
                console.log("Arabic has been set");
                this._setTexts(AR);
                return;
            }
            switch(languageCode){
                default:
                case 'en-GB':
                    this._setTexts(GB)
                    break
                case 'da-DK':
                    this._setTexts(DK);
                    break
                case 'de-DE':
                    this._setTexts(DE)
                    break;
                // case 'sq-AL':
                case 'ar-AR':
                    this._setTexts(AR)
                    break;
                case 'en-CA':
                    this._setTexts(CA)
                    break;
                case 'en-US':
                    this._setTexts(US)
                    break;
                case 'es-ES':
                    this._setTexts(ES)
                    break;
                case 'pt-PT':
                    this._setTexts(PT)
                    break;
                case 'ro-RO':
                    this._setTexts(RO)
                    break;
                case 'it-IT':
                    this._setTexts(IT);
                    break;
                case 'sv-SE':
                    this._setTexts(SE)
                    break;
            }
        }
        catch(err){
            console.error(err);
            this.services.logger.error(`Failed to load texts for language ${languageCode}`, err)
            this._setTexts({})
        }
        
        // this.services.logger.info(`Loading language for code ${languageCode}`)
        // import(`./translations/texts/texts.${languageCode}.json`).then( module => {
        //     this.services.logger.info(`Loading language for code ${languageCode} succeeded!`)
        //     // this._setTexts(module.default)
        //     this._setTexts(module)
        // }).catch((err) => {
        //     console.error(err);
        //     this.services.logger.error(`Failed to load texts for language ${languageCode}`, err)
        //     this._setTexts({})
        // })

    }

    private _setTexts(texts: Record<string, string>): void {
        runInAction(()=>{
            this._texts = texts;
        })
    }

    translate(template: string): string {
        if(this.texts[template]){
            template = this.texts[template];
        }
        return  template;
    }

    translationFor(template: string): TranslationWithParams {
        if(this.texts[template]){
            template = this.texts[template];
        }
        return new TranslationWithParams(template);
    }

}

import { makeObservable, observable } from "mobx";
import { JsxEmit } from "typescript";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import { IChatStateService, IOracleChat } from "./chat-state.service.interface";



export class ChatStateService 
                    extends ServiceBase
                    implements IChatStateService {
    
    constructor(services: IServiceFactory){
        super(services);
        makeObservable( this, {
            activeChat: observable.ref
        })
    }

    setChatWindowSize = (width: number, height: number) => {
        if(!this.activeChat) return;

        if(width === 0 || height === 0 ) return;
        this.activeChat.setSize(
                `${width??0}px`,
                `${height??0}px`);

        // this.activeChat.setSize("100%", "100%");
    };


    activeChat?: IOracleChat| null = null;

    setActiveChat(chat?: IOracleChat){
        this.activeChat = chat;
    }

    resetActiveChat() {
        this.activeChat = null;
    };
}

import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { defaultTheme } from "../default/default-theme";
import { CasinoMainScalePalette } from "./casino-main-scale-palette";

export const casinoTheme = {
    colors: {
        ...defaultTheme.colors,
        primary: CasinoMainScalePalette.Primary,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.Dark,
        outOfHoursContainerText: CasinoMainScalePalette.Primary,
        primaryButtonText: DefaultGreyScalePalette.Dark,
        primaryButtonBackground: CasinoMainScalePalette.Primary,
        primaryButtonBackgroundHover: "#ACFA59",
        iconColors: {
            ...defaultTheme.colors.iconColors
        } as IIconColors,
        helpCenterMainPageColors: {
            ...defaultTheme.colors.helpCenterMainPageColors,
            userName: CasinoMainScalePalette.Primary,
        } as IHelpCenterMainPageColors,
        livePersonChipsColor : CasinoMainScalePalette.Primary,
        livePersonChipsBorderColor: CasinoMainScalePalette.Primary,
        livePersonSendButtonColor: CasinoMainScalePalette.Primary,
    } as GeneralColors,
    images: {
        ...defaultTheme.images,
        amandaBubble: "Amanda Casino Bubble 55x55px",
        amandaBubbleWithNotification: "Amanda Casino Bubble Not 55x55px"
    },
    textKeys: {
        ...defaultTheme.textKeys,        
        faqButtonLabel: "AllCaps_Faq_Button_Label",
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        chatButtonLabel: "AllCaps_Chat_Button_Label",
        noLabel: "AllCaps_No_Label",
        yesLabel: "AllCaps_Yes_Label"
    },
};

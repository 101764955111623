import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { defaultTheme } from "../default/default-theme";
import { MRGSpecialScalePalette } from "./mrg-special-scale-palette";

export const mrgTheme = {
    colors: {
        primary: MRGSpecialScalePalette.MG_Green,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: MRGSpecialScalePalette.MG_Green,
        text: MRGSpecialScalePalette.MG_Light_Dark,
        secondaryText: MRGSpecialScalePalette.MG_Light_Dark,
        secondaryHeader: MRGSpecialScalePalette.MG_Light_Dark,
        mainWindowBackground: DefaultGreyScalePalette.White,
        helpCenterBackground: DefaultGreyScalePalette.White,
        urgentMessageTitleBarBackground: MRGSpecialScalePalette.MG_Green,
        urgentMessageBackground: MRGSpecialScalePalette.MG_Light_Grey,
        questionMarkBackground: DefaultGreyScalePalette.White,
        faqUrgentMessageBackground: MRGSpecialScalePalette.MG_Light_Grey,
        primaryButtonText: DefaultGreyScalePalette.White,
        primaryButtonBackground: MRGSpecialScalePalette.MG_Green,
        primaryButtonBackgroundHover: "#5A907C",
        secondaryButtonText: MRGSpecialScalePalette.MG_Green,
        secondaryButtonBackground: DefaultGreyScalePalette.White,
        confirmationDialogText: MRGSpecialScalePalette.MG_Light_Dark,
        urgentMessageText: MRGSpecialScalePalette.MG_Medium_Dark,
        outOfHoursBackground: DefaultGreyScalePalette.White,
        outOfHoursContainerBackground: MRGSpecialScalePalette.MG_Light_Grey,
        outOfHoursContainerText: MRGSpecialScalePalette.MG_Medium_Dark,
        outOfHoursText: MRGSpecialScalePalette.MG_Medium_Dark,
        outOfHoursButtonBackground: "#00ff00",
        outOfHoursButtonText: DefaultGreyScalePalette.White,
        chatPageUrgentMessageBackground: DefaultGreyScalePalette.Grey,
        messageBackground: DefaultGreyScalePalette.MediumDark,
        popupBackground: "#FFFFFF",
        userMessageBackground: MRGSpecialScalePalette.MG_Green,
        helpCenterButtonsBackground: DefaultGreyScalePalette.White,
        outOfHoursBorder: DefaultGreyScalePalette.MediumGrey,
        buttonHover: MRGSpecialScalePalette.MG_Green,
        buttonBorderColor: MRGSpecialScalePalette.MG_Light_Grey,
        headerButtonsColor: DefaultGreyScalePalette.White,
        livePersonAgentMessageColor: DefaultGreyScalePalette.White,
        livePersonComposer: MRGSpecialScalePalette.MG_Light_Grey,
        livePersonComposerText: MRGSpecialScalePalette.MG_Light_Dark,
        livePersonInputText: MRGSpecialScalePalette.MG_Light_Dark,
        livePersonVisitorBackgroundMessageColor: MRGSpecialScalePalette.MG_Light_Grey,
        livePersonVistorMessageBorderColor: MRGSpecialScalePalette.MG_Light_Grey,
        livePersonAgentBackgroundMessageColor: MRGSpecialScalePalette.MG_Green,
        livePersonChipsColor : MRGSpecialScalePalette.MG_Green,
        livePersonChipsHoverColor: DefaultGreyScalePalette.White,
        livePersonChipsBorderColor: MRGSpecialScalePalette.MG_Green,
        livePersonSendButtonColor: MRGSpecialScalePalette.MG_Green,
        chatTitle: DefaultGreyScalePalette.White,
        iconColors: {
            default: MRGSpecialScalePalette.MG_Medium_Green,
            disabledPrimary: DefaultGreyScalePalette.MediumGrey,
            disabledSecondary: DefaultGreyScalePalette.Dark,
            hover: MRGSpecialScalePalette.MG_Green,
            openLinkIcon: MRGSpecialScalePalette.MG_Light_Dark,
            faqSpacingLine: DefaultGreyScalePalette.LightGrey,
            copyIcon: MRGSpecialScalePalette.MG_Light_Dark
        },
        helpCenterMainPageColors: {
            greetingMessage: MRGSpecialScalePalette.MG_Light_Dark,
            userName: MRGSpecialScalePalette.MG_Medium_Green,
            faqTitle: MRGSpecialScalePalette.MG_Light_Dark
        }
    } as GeneralColors,
    images: {
        ...defaultTheme.images,
        amandaBubble: "Ace Default Bubble 55x55px",
        amandaBubbleWithNotification: "Ace Default Bubble Not 55x55px"
    },
    fontFamily: "'Montserrat'",
    textKeys: {
        ...defaultTheme.textKeys,   
        greetingMessage: "MRG_Greeting_Message",
        chatButtonLabel: "MRG_Chat_Button_Label",
        hiMessage: ""
    },
    fontWeight: {
        normal: '400',
        weights: {
            greetingMessage: '600',
            faqTitle: '700',
            faqQuestion: '600',
            outOfHoursCloseButton: '700',
            outOfHoursText: '500',
            outOfHoursHours: '700',
            outOfHoursCountry: '500',
            urgentMessageText: '700',
            urgentMessageCloseButton: '700',
            livePersonAgentMessage: '400'
        },
        opacity: {
            outOfHoursText: "1",
            versionNumber: "1"
        }
    }
};


import React from 'react';
import styled from "styled-components";
import { servicesVersion } from "typescript";
import { useChatContext } from "../../services/contexts/chat-context";


const OutOfHoursPopupContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const OutOfHoursPopupOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.62) 0% 0% no-repeat padding-box;
    z-index: 20000;
`;

const OutOfHoursWindow = styled.div`
    margin-top: 121px;
    margin-left: 7%;
    width: 86%;
    margin-right: 7%;
    height: 480px;
    background: ${(props: any) => props.theme.colors.outOfHoursBackground}; 0% 0% no-repeat padding-box;
    border-radius: 10px;
    z-index: 20001;
    opacity: 1;
    position: absolute;
`;

const AmandaSleepingImg = styled.img`
    margin-top: 40px;
    height: auto;
    width: auto;
    max-width: 90px;
    max-height: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

interface SorryParagraphProps {
    isDisabled: boolean | undefined;
}

const SorryParagraph = styled.p<SorryParagraphProps>`
    margin-top: ${(props: any) => props.isDisabled ? "120px" : "50px"};
    width: 76%;
    margin-left: 12%;
    margin-right: 12%;
    color: ${(props: any) => props.theme.colors.outOfHoursContainerText};
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.12px;
    text-align: center;
    font-family: ${(props: any) => props.theme.fontFamily};
`;

const HoursContainer = styled.div`
    padding: 2px;
    margin-top: 40px;
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
    background: ${(props: any) => props.theme.colors.outOfHoursContainerBackground};
    border-radius: 20px;

    > p {
        margin-bottom: 7px !important;
    }
`;

const HoursTextParagraph = styled.p`
    padding-top: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.outOfHoursText};
    letter-spacing: 0.98px;
    color: ${(props: any) => props.theme.colors.outOfHoursText};
    opacity: ${(props: any) => props.theme.fontWeight.opacity.outOfHoursText};
`;

interface HoursParagraphProps {
    isSingleInterval: boolean;
    isSingleRow: boolean;
}

const HoursParagraph = styled.p<HoursParagraphProps>`
    text-align: center;
    font-size: ${(props: any) => props.isSingleInterval ? "24px" : "15px"};
    letter-spacing: 3px;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.outOfHoursHours};
    color: ${(props: any) => props.theme.colors.outOfHoursText};
    padding-left: ${(props: any) => props.theme.fontWeight.padding.outOfHoursHours};
    padding-right: ${(props: any) => props.theme.fontWeight.padding.outOfHoursHours};

    // margin-top: ${(props: any) => props.isSingleInterval ? "-2px" : props.isSingleRow ? "-3px" : "-5px"};
`;

const HoursCountryParagraph = styled.p`
    width: 100%;
    text-align: center;
    margin-top -3px;
    font-size: 14px;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.outOfHoursCountry};
    letter-spacing: 0.98px;
    color: ${(props: any) => props.theme.colors.outOfHoursText};
    opacity: ${(props: any) => props.theme.fontWeight.opacity.outOfHoursText};
`;

const CloseButton = styled.button`
    position: absolute;
    bottom: 30px;
    width: 86%;
    height: 43px;
    margin-left: 7%;
    margin-right: 7%;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.outOfHoursCloseButton};
    font-size: 14px;
    border-radius: 20px;
    color: ${(props: any) => props.theme.colors.primaryButtonText};
    background-color: ${(props: any) => props.theme.colors.primaryButtonBackground};
    border: 2px solid;
    cursor: pointer;
    border-color: ${(props: any) => props.theme.colors.primaryButtonBackground};
    font-family: ${(props: any) => props.theme.fontFamily};

    :hover {
        color: ${(props: any) => props.theme.colors.primaryButtonText};
        background-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
        border-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
    }

    :active {
        background-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
        color: ${(props: any) => props.theme.colors.primaryButtonText};
        border-color: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
    }
`;

export const OutOfHoursPopup: React.FC<any> = (props: {onClose : () => void}) => {
    const { 
        resourceService,
        chatData,
        languageService, 
        themes
    } = useChatContext().services;
    console.log(`hours ${JSON.stringify(chatData.chatHours)}`);
    // const hoursMessage = chatData.chatHours?.outsideOfWorkingHoursText ? 
    //             chatData.chatHours.outsideOfWorkingHoursText.substring(0, 
    //             chatData.chatHours.outsideOfWorkingHoursText.indexOf("<div>")) : "";


    // const hours = chatData.chatHours?.outsideOfWorkingHoursText ? 
    //             chatData.chatHours.outsideOfWorkingHoursText.substring(chatData.chatHours.outsideOfWorkingHoursText.indexOf("<div>") + 5, 
    //             chatData.chatHours.outsideOfWorkingHoursText.indexOf("</div>")) : "";

    // const zone = chatData.chatHours?.outsideOfWorkingHoursText ? 
    //             chatData.chatHours.outsideOfWorkingHoursText.substring(chatData.chatHours.outsideOfWorkingHoursText.indexOf("</div>") + 6, 
    //             chatData.chatHours.outsideOfWorkingHoursText.length) : "";

    const hours = !chatData.chatHours?.isDisabled && chatData.chatHours?.openHours ? chatData.chatHours?.openHours : "";
    const zone = !chatData.chatHours?.isDisabled && chatData.chatHours?.openHours ? chatData.chatHours?.openHoursTimezone: ""; 
    const hoursMessage = languageService.translate('ChatAvailable_Between');


    const isSingleInterval = hours.length < 12 ? true : false;
    const isSingleRow = hours.length < 26 ? true : false; 
    const formattedHours = chatData.chatSettings?.use24hTimeFormat ? hours : formatTimeRange(hours);

    return (
        <OutOfHoursPopupContainer id="oohPopupContainer">
            <OutOfHoursPopupOverlay/>
            <OutOfHoursWindow id="disabled_chat">
                <AmandaSleepingImg src={themes.currentTheme.assets.images.amanda_help_sleeping_2x_png} />
                <SorryParagraph isDisabled={chatData.chatHours?.isDisabled}>{languageService.translate('ChatUnavailable_Message')}</SorryParagraph>
                { !chatData.chatHours?.isDisabled &&
                    <HoursContainer id="out_of_hours_container">
                        <HoursTextParagraph>{hoursMessage}</HoursTextParagraph>
                        <HoursParagraph isSingleInterval={isSingleInterval} isSingleRow={isSingleRow}>{formattedHours}</HoursParagraph>
                        <HoursCountryParagraph>{zone}</HoursCountryParagraph>
                    </HoursContainer>
                }
                <CloseButton onClick={() => {props.onClose()}}>{languageService.translate(themes.currentTheme.textKeys.outOfHoursCloseLabel)}</CloseButton>
            </OutOfHoursWindow>
        </OutOfHoursPopupContainer>
    )

    function formatTimeRange(timeRange: string): string {
        // Regular expression to match time ranges in the format "hh:mm-hh:mm"
        const timeRegex = /(\d{2}:\d{2})-(\d{2}:\d{2})/g;
    
        // Function to convert a single time from 24-hour format to 12-hour format
        function convertTo12HourFormat(time: string): string {
            const [hours] = time.split(':');
            const hour = parseInt(hours, 10);
            const period = hour < 12 ? 'am' : 'pm';
            const formattedHour = hour === 0 || hour === 12 ? 12 : hour > 12 ? hour - 12 : hour;
            return `${formattedHour}${period}`;
        }
    
        // Replace each time range in the string with its 12-hour format equivalent
        const formattedRange = timeRange.replace(timeRegex, (match, start, end) => {
            return `${convertTo12HourFormat(start)} - ${convertTo12HourFormat(end)}`;
        });
    
        return formattedRange;
    }
}
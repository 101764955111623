import React from "react";
import { colorify } from "lottie-colorify";
import Lottie from "lottie-react";
import { FC } from "react";
import styled from "styled-components";
import spinner from "../../resources/loading-spinner-lottie.json";
import { useChatContext } from "../../services/contexts/chat-context";

const WidgetLoaderContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
  padding-top: 250px;
`;

export const LoadingSpinner: FC<any> = () => {
    const {themes} = useChatContext().services;

    return <WidgetLoaderContainer>
        <Lottie
            animationData={colorify(
            [
                themes.currentTheme.colors.primary,
                themes.currentTheme.colors.primary,
            ],
            spinner
            )}
            height={120}
            width={120}
        />
    </WidgetLoaderContainer>;
};
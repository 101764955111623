// import { ILogger } from "@sparkware/uc-sdk-core/lib/logger/logger.interface";
import { AnalyticsEventType } from "../types/global";
import { IEventData } from "./models/event-data.interface";
import { LogFormatV2 } from "./models/log-format-v2.interface";
import { ServiceBase } from "./service-base";
import { IServiceBase } from "./service-base.interface";
import { IServiceFactory } from "./service-factory.interface";
// import { ILogger} from "@sparkware/uc-sdk-core";

// export interface ILogger {
//   debug(message: string, data?: any): void;
//   info(message: string, data?: any): void;
//   warn(message: string, data?: any): void;
//   error(message: string, data?: any): void;
//   table(message: string, data?: any): void;
//   log(message: string, data?: any): void;
// }

export interface ILogger {   
  debug(text: string, ...args: any[]): void;   
  log(text: string, ...args: any[]): void;   
  warn(text: string, ...args: any[]): void;    
  error(text: string, ...args: any[]): void;   
  table(text: string, tabularData: any): void;  
  info(text: string, ...args: any[]): void;   
}

export interface ILoggerService extends IServiceBase, ILogger {}

export class LoggerService extends ServiceBase implements ILoggerService {
  constructor(services: IServiceFactory) {
    super(services);
  }
  debug(message: string, data?: any): void {
    console.debug(message);
    this.services.clickStream.send("Debug", message, data);
  }

  info(message: string, data?: any): void {
    console.info(message);
    this.services.clickStream.send("Information", message, data);
  }

  warn(message: string, data?: any): void {
    console.warn(message);
    this.services.clickStream.send("Warning", message, data);
  }

  error(message: string, data?: any): void {
    console.error(message);
    this.services.clickStream.send("Error", message, data);
  }

  table(message: string, data?: any): void {
    console.error(message);
    this.services.clickStream.send("Error", message, data);
  }

  log(message: string, data?: any): void {
    console.error(message);
    this.services.clickStream.send("Error", message, data);
  }

  // debug(message: string, params: IEventData, eventType: AnalyticsEventType): void {
  //     debugger
  //     //this.services.clickStream.Send(message, params, eventType);
  //     console.debug(message + JSON.stringify(params))

  // }
  // info(message: string, params: IEventData, eventType: AnalyticsEventType): void {
  //     //this.services.clickStream.Send(message, params, eventType);
  //     console.info(message + JSON.stringify(params))
  // }
  // warning(message: string, params: IEventData, eventType: AnalyticsEventType): void {
  //     //this.services.clickStream.Send(message, params, eventType);
  //     console.warn(message + JSON.stringify(params))
  // }
  // error(message: string, params: IEventData, eventType: AnalyticsEventType, err?: any): void {
  //     //this.services.clickStream.Send(message, params, eventType);
  //     console.error(message + JSON.stringify(params))
  // }
}

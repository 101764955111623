import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const CloseButtonSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6168 14.4959L3.18175 1.0609C2.59597 0.475118 1.64622 0.475118 1.06043 1.0609C0.474647 1.64669 0.474647 2.59644 1.06043 3.18222L14.4955 16.6173C15.0812 17.203 16.031 17.203 16.6168 16.6173C17.2026 16.0315 17.2026 15.0817 16.6168 14.4959Z" fill={themes.currentTheme.colors.headerButtonsColor}/>
      <path d="M3.18205 16.617L16.6171 3.182C17.2029 2.59621 17.2029 1.64646 16.6171 1.06068C16.0313 0.474891 15.0815 0.474891 14.4958 1.06068L1.06073 14.4957C0.474939 15.0815 0.474939 16.0312 1.06073 16.617C1.64651 17.2028 2.59626 17.2028 3.18205 16.617Z" fill={themes.currentTheme.colors.headerButtonsColor}/>
    </svg>

  );
});




import React, { FC, useContext, useEffect, useState } from "react";
import {
  ChatContext,
  useChatContext,
} from "../../services/contexts/chat-context";
import styled from "styled-components";

const ClosePopupContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ClosePopupOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  z-index: 20000;
  opacity: 0.75;
`;

const ClosepopupWindow = styled.div<{}>`
  width: 86%;
  height: 288px;
  color: white;
  position: absolute;
  z-index: 20000;
  top: 50%;
  margin: -206px 7% 0;
  background:  ${(props: any) => props.theme.colors.popupBackground} 0% 0% no-repeat padding-box;
  border: 2px solid #4c4c4c;
  border-radius: 20px;
  opacity: 1;
  line-height: 1.575;
`;

const TitleContainer = styled.div<{}>`
  height: 60px;
  background: ${(props: any) => props.theme.colors.mainWindowBackground} 0% 0% no-repeat padding-box;
  border-radius: 18px 18px 0px 0px;
  opacity: 1;
`;

const TitleText = styled.p<{}>`
  text-align: center;
  font-family: ${(props: any) => props.theme.fontFamily} !important;
  font-weight: bold !important;
  letter-spacing: 1.96px;
  color:  ${(props: any) => props.theme.colors.secondary};
  text-transform: capitalize;
  opacity: 1;
  font-size: 14px;
  line-height: 60px;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const Message = styled.p<{}>`
  text-align: center;
  font-family: ${(props: any) => props.theme.fontFamily} !important;
  font-weight: bold !important;
  letter-spacing: 1.96px;
  color: ${(props: any) => props.theme.colors.secondary};
  opacity: 1;
  padding: 10px 10px;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const Icon = styled.img<{}>`
  height: 76px;
  background: transparent url("img/logout-icon.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  display: block;
  margin: auto;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  margin-left: 6.5%;
  width: 40%;
  height: 40px;
  line-height: 40px;
  border-radius: 60px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
  float: left;
`;

const ButtonNoContainer = styled(ButtonContainer)`
  background: ${(props: any) => props.theme.colors.primary}; 0% 0% no-repeat padding-box;
  border: 2px solid ${(props: any) => props.theme.colors.primary};
  color:  ${(props: any) => props.theme.colors.popupBackground};

  &:hover, &:active{
    background: ${(props: any) => props.theme.colors.popupBackground}; 0% 0% no-repeat padding-box;
    border: 2px solid ${(props: any) => props.theme.colors.primary};
    color:  ${(props: any) => props.theme.colors.primary};
}
`;

const ButtonYesContainer = styled(ButtonContainer)`
  background: ${(props: any) => props.theme.colors.popupBackground}; 0% 0% no-repeat padding-box;
  border: 2px solid ${(props: any) => props.theme.colors.outOfHoursBorder};
  color: ${(props: any) => props.theme.colors.outOfHoursBorder};

  &:hover,
  &:active {
    border: 2px solid ${(props: any) => props.theme.colors.buttonHover}; ;
    color: ${(props: any) => props.theme.colors.buttonHover}; 
  }
`;

const ButtonText = styled.div`
  text-align: center;
  font-family: ${(props: any) => props.theme.fontFamily};
  font-weight: bold;
  font-size: 14px;
  // text-transform: uppercase;
  opacity: 1;
  vertical-align: -webkit-baseline-middle;
  letter-spacing: 1.12px;
`;

export const ClosePopup: React.FC<any> = (props: {
  onPopupSelection: (value: boolean) => void;
}) => {
  const { themes, languageService } = useChatContext().services;

  return (
    <ClosePopupContainer>
      <ClosePopupOverlay />
      <ClosepopupWindow>
        <TitleContainer>
          <TitleText>
            {languageService.translate("ClosingChat_Title")}
          </TitleText>
          <Icon
            src={themes.currentTheme.assets.images.live_chat_icon_2x_png}
            alt="agent icon"
          ></Icon>
          <Message>{languageService.translate("ClosePopup_Message")}</Message>
          <div>
            <ButtonNoContainer
              onClick={() => {
                console.log(false);
                props.onPopupSelection(false);
              }}
            >
              <ButtonText>{languageService.translate(themes.currentTheme.textKeys.noLabel)} </ButtonText>
            </ButtonNoContainer>

            <ButtonYesContainer
              onClick={() => {
                console.log(true);
                props.onPopupSelection(true);
              }}
            >
              <ButtonText>{languageService.translate(themes.currentTheme.textKeys.yesLabel)} </ButtonText>
            </ButtonYesContainer>
          </div>
        </TitleContainer>
      </ClosepopupWindow>
    </ClosePopupContainer>
  );
};

import { makeObservable } from "mobx";
import { observable, action } from "mobx";
import { FaqReportColumns } from "../../enums/faq-report";
import Axios from "../../utils/AxiosInstance";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import {
  FilterItem,
  IOsvcService,
  OsvcFaqReport,
  OsvcReportParams,
  OsvcReportStatus,
} from "./osvc.service.interface";

export class OsvcService extends ServiceBase implements IOsvcService {
  reportResult: OsvcFaqReport[] = new Array(
    ...[
      //   {
      //     // id: 0,
      //     question: "How do I contact 888?",
      //     answer: "You will get it when I will see the back of the neck!",
      //   },
      //   {
      //     question: "Why can't I login to my account?",
      //     answer: "You will get it when I will see the back of the neck!",
      //   },
    ]
  );
  reportStatus: OsvcReportStatus = {
    hasError: false,
    hasExecuted: false,
  };

  constructor(services: IServiceFactory) {
    super(services);
    makeObservable(this, {
      reportResult: observable.ref,
      reportStatus: observable.ref,
      setReportStatus: action.bound,
      RunReport: action.bound,
    });
  }

  async RunReport(parameters: OsvcReportParams): Promise<void> {
    console.log(`Run OSVC report with ${JSON.stringify(parameters)}`);
    this.services.logger.info("Run Osvc Report with @parameters", {
      parameters: parameters,
    });

    // this.setReportStatus({
    //   hasError: true,
    //   hasExecuted: true,
    // });
    // return;
    this.reportResult = [];
    const response = await Axios(this.services)
      .post(this.environmentSettings.apiUrl + "oscp-proxy/run-report", {
        requestParams: {
          id: parameters.id,
          filters: parameters.filters,
          limit: parameters.limit,
        },
        interfaceUrl: parameters.interfaceUrl,
      })
      .catch((e) => {
        this.setReportStatus({
          hasError: true,
          hasExecuted: true,
        });

        throw new Error(`Could not load osvc run-report results: ${e}`);
      });

    this.services.logger.info(`Osvc Run-Report response", ${JSON.stringify(response)}`);
    if (!response.data.rows) {
      throw new Error(`Could not load osvc run-report results.`);
    }
    // console.log(response.data.rows);
    // getMockReportResult().forEach((element: OsvcFaqReport[]) => {
    response.data.rows.forEach((element: OsvcFaqReport[]) => {
      this.reportResult?.push({
        summary: element[FaqReportColumns.Summary].toString(),
        answerUrl: element[FaqReportColumns.Url].toString(),
      });
    });
    this.setReportStatus({
      hasError: false,
      hasExecuted: true,
    });
    // console.log(response);
  }
  setReportStatus(value: OsvcReportStatus) {
    this.reportStatus = value;
  }

  getMockReportResult() : any {
    return new Array(["100","2147483647","Poker FAQ - General Info","888-external-en","3345","137","https://888-external-en.custhelp.com/app/answers/detail/a_id/3345/p/137"],
    ["100","2147483647","888.dk - Denmark Regulation","888-external-en","11584","137","https://888-external-en.custhelp.com/app/answers/detail/a_id/11584/p/137"],
    ["100","2147483647","888 Affiliates","888-external-en","13591","823","https://888-external-en.custhelp.com/app/answers/detail/a_id/13591/p/823"],
    ["100","2147483647","WL Affiliate Verification","888-external-en","14235","823","https://888-external-en.custhelp.com/app/answers/detail/a_id/14235/p/823"],
    ["100","2147483647","VIP - Source of Funds procedure","888-external-en","11894","794","https://888-external-en.custhelp.com/app/answers/detail/a_id/11894/p/794"]);
    
  }

  getElementContent(element: string, start: string, end: string): string {
    return element.substring(
      element.indexOf(start),
      element.indexOf(end) + end.length
    );
  }
}

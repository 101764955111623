import { IEventData } from "./event-data.interface";

export class LogFormatV2 implements ILogFormatV2 {
    eventHeader: IEventHeader;
    sender: ISender = {
        versionNumber: "0",
        buildNumber: "0",
        serverName: "0",
        componentName: "CR_CHAT",
    };
    sessionData: ISessionData;
    userData: IUserData;
    eventData: IEventData;
    message: string;
    fields: {
        env: string
    };
    correlationId: string;

    constructor (_: any) {
        this.eventHeader = _.eventHeader;
        this.sessionData = _.sessionData;
        this.userData = _.userData;
        this.eventData = _.eventData;
        this.message = _.message;
        this.fields = _.fields;
        this.correlationId = _.correlationId;

        // this.eventHeader.correlationID = _.eventHeader.correlationID;
        // this.eventHeader.level = _.eventHeader.level;
        // this.eventHeader.target = _.eventHeader.target;
        // this.eventHeader.clientDateTime = _.eventHeader.clientDateTime;
        // this.eventHeader.serverDateTime = _.eventHeader.serverDateTime;
        // this.eventHeader.message = _.eventHeader.message;
        // this.sessionData = _.sessionData;
        // this.userData = _.userData;
        // this.eventData = _.eventData;

        return this;
    }
}

export interface ILogFormatV2 {
    eventHeader: IEventHeader,
    sender: ISender,
    sessionData?: ISessionData,
    userData?: IUserData,
    eventData: IEventData
}


export interface IEventType {
    name: string,
    type: string[]
}


export interface IUserData {
    cid: number,
    brand: string,
    brandID: number
}

export interface ISessionData {
    globalSessionID: number,
    playerSessionID: number
}

export interface ISender {
    systemID?: string,
    versionNumber: string,
    buildNumber: string,
    serverName: string,
    ipv4?: string,
    ipv6?: string,
    componentName: string,
    subComponentName?: string
}

export interface IEventHeader {
    correlationID: string,
    businessCorrelationID?: string,
    sessionID?: string,
    message? : string,
    serverDateTime?: string,
    clientDateTime?: string,
    eventDateTime?: string,
    level: string,
    environment: string,
    target: string,
    logVersionFormat: string,
    appID?: string,
    productPackageID?: string,
    sequentialNumber?: number,
    eventType: IEventType
}

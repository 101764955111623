import { ImageEnumType } from "../../architecture/asset-resolver/make-assets/image-enum.type";
import { IAssetResolver } from "../../architecture/asset-resolver/asset-resolver.interface";
import { makeAsset } from "../../architecture/asset-resolver/make-assets/make-assets";
import { IWidgetTheme } from "../../architecture/widget-theme/interface/widget-theme.interface";
import { makeWidgetTheme } from "../../architecture/widget-theme/make-widget-theme";
import { sportTheme } from "./sport-theme";

export function makeSportTheme (assetResolver: IAssetResolver): IWidgetTheme {
    return makeWidgetTheme( 
        assetResolver,
        sportTheme.colors,
        {
            images:{
                avatar_help_center_2x_png: makeAsset('images/Avatar Help Center.png', assetResolver),
                bot_icon: makeAsset('images/Amanda@2x.png', assetResolver),
                agent_avatar: makeAsset('images/Live Agent Avatar 50x50px@2x.png', assetResolver),
                send_button_png: makeAsset('images/Send Button.png', assetResolver),
                send_button_2x_png: makeAsset('images/Send Button@2x.png', assetResolver),
                live_chat_icon_png: makeAsset('images/Sport Dublin/Live Chat Icon Sport Dublin.png', assetResolver),
                live_chat_icon_2x_png: makeAsset('images/Sport Dublin/Live Chat Icon Sport Dublin@2x.png', assetResolver),
                minimize_icon_png: makeAsset(`images/Sport Dublin/${sportTheme.images.amandaBubble}.png`, assetResolver),
                minimize_icon_2x_png: makeAsset(`images/Sport Dublin/${sportTheme.images.amandaBubble}@2x.png`, assetResolver),   
                minimized_new_message_icon_png: makeAsset(`images/Sport Dublin/${sportTheme.images.amandaBubbleWithNotification}.png`, assetResolver),
                minimized_new_message_icon_2x_png: makeAsset(`images/Sport Dublin/${sportTheme.images.amandaBubbleWithNotification}@2x.png`, assetResolver),  
                amanda_help_sleeping_png: makeAsset('images/AmandaHelpSleeping@2x.png', assetResolver),
                amanda_help_sleeping_2x_png: makeAsset('images/AmandaHelpSleeping@2x.png', assetResolver),
               
                infoIcon : makeAsset(`/images/${sportTheme.images.infoIcon}.svg`, assetResolver),
                infoIconHover : makeAsset(`/images/${sportTheme.images.infoIconHover}.svg`, assetResolver),

                typing_animation_gif: makeAsset('images/Typing-Animation.gif', assetResolver),
                close_button_png: makeAsset('images/close-survey.svg', assetResolver),
                close_button_2x_png: makeAsset('images/X button@2x.png', assetResolver),
                minimize_button_png: makeAsset(`images/Minimize.png.png`, assetResolver),
                minimize_button_2x_png: makeAsset(`images/Minimize@2x.png`, assetResolver),

                urgent_message_icon_svg: makeAsset(`images/urgent-message-icon.svg`, assetResolver),

                transfer_to_agent: makeAsset(`animations/transfer_to_agent_sport.json`, assetResolver)

            }  as ImageEnumType
        }, undefined, sportTheme.textKeys, undefined)
}

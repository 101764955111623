import { LP_CHAT_APP_NAME } from "../../enums/constants";
import { ILivePersonEvent } from "../../types/live-person-models";
import { ServiceBase } from "../service-base";
import { ILivePersonService } from "./live-person.service.interface";

export class LivePersonService extends ServiceBase implements ILivePersonService {

    public closeLPChatAtLogout() {
        const temporaryLPContainer = this.createTemporaryLPContainer();
        this.moveLPChatToTemporaryContainer(temporaryLPContainer);
        this.closeLPChat(temporaryLPContainer);
    }

    public hideLPChat() {
        const temporaryLPContainer = this.createTemporaryLPContainer();
        this.moveLPChatToTemporaryContainer(temporaryLPContainer);
    }

    private createTemporaryLPContainer() {
        const temporaryLPContainer = document.createElement('div');
        temporaryLPContainer.setAttribute('id', 'temporaryLPContainer');
        temporaryLPContainer.style.display = 'none';
    
        return temporaryLPContainer;
    }
    
    private moveLPChatToTemporaryContainer(temporaryLPContainer: HTMLDivElement) {
        const deviceClass = this.getDeviceClass();

        const lpWindow = document.getElementsByClassName(deviceClass)[0];
        if(!lpWindow) {
            return;
        }
        temporaryLPContainer.appendChild(lpWindow);
    
        document.body.appendChild(temporaryLPContainer);
    }
    
    public getDeviceClass() {
        const device = window.lpTag.unifiedWindow?.customization?.deviceClass;
        const deviceClass = `lp${device.substring(device.indexOf("_"))}`;
    
        return deviceClass;
    }
    
    private closeLPChat(temporaryLPContainer: HTMLDivElement) {
        const lpCloseConversationButton = window.document.getElementsByClassName("lp_end-chat-action-icon")[0];
        if (lpCloseConversationButton) {
            (lpCloseConversationButton as HTMLElement).click();
        
            const isChatInteractive: boolean = window.lpTag.events.hasFired().filter((event: ILivePersonEvent) => 
                event.eventName === 'state' && event.appName === LP_CHAT_APP_NAME).slice(-1)[0]?.data === 'interactive';
        
            if (isChatInteractive) {
                setTimeout(() => this.confirmCloseLPChat(temporaryLPContainer), 100);
            }
        }
    }
    
    private confirmCloseLPChat(temporaryLPContainer: HTMLDivElement) {
        const lpConfirmationButton = window.document.getElementsByClassName("lp_confirm_button")[0];
        if (lpConfirmationButton) {
            (lpConfirmationButton as HTMLElement).click();
        }
    }
}
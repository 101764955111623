
import { IAssetResolver } from "../../architecture/asset-resolver/asset-resolver.interface";
import { ImageEnumType } from "../../architecture/asset-resolver/make-assets/image-enum.type";
import { makeAsset } from "../../architecture/asset-resolver/make-assets/make-assets";
import { IWidgetTheme } from "../../architecture/widget-theme/interface/widget-theme.interface";
import { makeWidgetTheme } from "../../architecture/widget-theme/make-widget-theme";
import { MiCasinoTheme } from "./mi-casino-theme";

export function makeMiCasinoTheme (assetResolver: IAssetResolver): IWidgetTheme {
    return makeWidgetTheme( 
        assetResolver,
        MiCasinoTheme.colors,
        {
            images:{
                avatar_help_center_2x_png: makeAsset('images/Avatar Help Center.png', assetResolver),
                bot_icon: makeAsset('images/Amanda@2x.png', assetResolver),
                agent_avatar: makeAsset('images/Live Agent Avatar 50x50px@2x.png', assetResolver),
                send_button_png: makeAsset('images/Send Button.png', assetResolver),
                send_button_2x_png: makeAsset('images/Send Button@2x.png', assetResolver),
                live_chat_icon_png: makeAsset('images/SI/Live Chat Icon SI.png', assetResolver),
                live_chat_icon_2x_png: makeAsset('images/SI/Live Chat Icon SI@2x.png', assetResolver),
                minimize_icon_png: makeAsset(`images/SI/${MiCasinoTheme.images.amandaBubble}.png`, assetResolver),
                minimize_icon_2x_png: makeAsset(`images/SI/${MiCasinoTheme.images.amandaBubble}@2x.png`, assetResolver),
                minimized_new_message_icon_png: makeAsset(`images/SI/${MiCasinoTheme.images.amandaBubbleWithNotification}.png`, assetResolver), 
                minimized_new_message_icon_2x_png: makeAsset(`images/SI/${MiCasinoTheme.images.amandaBubbleWithNotification}@2x.png`, assetResolver), 

                infoIcon : makeAsset(`/images/${MiCasinoTheme.images.infoIcon}.svg`, assetResolver),
                infoIconHover : makeAsset(`/images/${MiCasinoTheme.images.infoIconHover}.svg`, assetResolver),

                amanda_help_sleeping_png: makeAsset('images/AmandaHelpSleeping@2x.png', assetResolver),
                amanda_help_sleeping_2x_png: makeAsset('images/AmandaHelpSleeping@2x.png', assetResolver),
                typing_animation_gif: makeAsset('images/Typing-Animation.gif', assetResolver),
                close_button_png: makeAsset('images/X button.png', assetResolver),
                close_button_2x_png: makeAsset('images/X button@2x.png', assetResolver),
                minimize_button_png: makeAsset(`images/Minimize.png.png`, assetResolver),
                minimize_button_2x_png: makeAsset(`images/Minimize@2x.png`, assetResolver),
                urgent_message_icon_svg: makeAsset(`images/urgent-message-icon.svg`, assetResolver),

                transfer_to_agent: makeAsset(`animations/transfer_to_agent_SI.json`, assetResolver)

            } as ImageEnumType
        }, undefined, MiCasinoTheme.textKeys)
}


import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

  export const SendIconSVG: FC = observer(() => {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <g id="send_file_icon">
                <path
                    id="send"
                    d="M0 18.164V11.116L15.7138 9.082L0 7.05V0L22 9.082L0 18.164Z"
                    fill="#FFFFFF" //Fill color is inherited from button
                />
            </g>
        </svg>
    );
});

import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { defaultTheme } from "../default/default-theme";
import { PokerMainScalePalette } from "./poker-main-scale-palette";

export const pokerTheme = {
    colors: {
        ...defaultTheme.colors,
        primary: PokerMainScalePalette.Primary,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.Dark,
        outOfHoursContainerText: PokerMainScalePalette.Primary,
        livePersonChipsColor : PokerMainScalePalette.Primary,
        livePersonChipsBorderColor: PokerMainScalePalette.Primary,
        livePersonSendButtonColor: PokerMainScalePalette.Primary,
        primaryButtonText: DefaultGreyScalePalette.Dark,
        primaryButtonBackground: PokerMainScalePalette.Primary,
        primaryButtonBackgroundHover: "#59AAFA",
        iconColors: {
            ...defaultTheme.colors.iconColors,
        } as IIconColors,
        helpCenterMainPageColors: {
            ...defaultTheme.colors.helpCenterMainPageColors,
            userName: PokerMainScalePalette.Primary,
        } as IHelpCenterMainPageColors
    } as GeneralColors,
    images: {
        ...defaultTheme.images,
        amandaBubble: "Amanda Poker Bubble 55x55px",
        amandaBubbleWithNotification: "Amanda Poker Bubble Not 55x55px"
    },
    textKeys: {
        ...defaultTheme.textKeys,   
        faqButtonLabel: "AllCaps_Faq_Button_Label",
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        chatButtonLabel: "AllCaps_Chat_Button_Label",
        noLabel: "AllCaps_No_Label",
        yesLabel: "AllCaps_Yes_Label"
    },
};

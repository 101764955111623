import { IGreyScalePalette } from "../../greyscale-palette.interface";

export const DefaultGreyScalePalette: IGreyScalePalette = {
   Dark: "#0A0A0A",
   MediumDark: "#171717",
   LightDark: "#232323",
   Grey: "#2C2C2C",
   MediumGrey: "#717171",
   LightGrey: "#CCCCCC",
   White: "#FFFFFF",
   Transparent: "#FFFFFF00",
   DarkGray: "#0D0D0D"
};

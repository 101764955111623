import React from "react";
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const CopyIconSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      cursor="pointer"
      viewBox="0 0 7.392 8.864"
    >
      <g
        id="copy_support_id_icon"
        data-name="copy support id icon"
        transform="translate(-85)"
        opacity="0.6"
      >
        <path
          id="Path_4347"
          data-name="Path 4347"
          d="M90.177,97.63H85.64a.64.64,0,0,1-.64-.64V90.97a.64.64,0,0,1,.64-.64h4.537a.64.64,0,0,1,.64.64V96.99a.641.641,0,0,1-.641.64Zm-4.312-.866h4.087V91.2H85.866Z"
          transform="translate(0 -88.766)"
          fill={themes.currentTheme.colors.iconColors.copyIcon}
        />
        <path
          id="Path_4348"
          data-name="Path 4348"
          d="M193.176,7.755a.433.433,0,0,1-.433-.433V.866h-4.31a.433.433,0,0,1,0-.866h4.535a.64.64,0,0,1,.641.64V7.322A.433.433,0,0,1,193.176,7.755Z"
          transform="translate(-101.217)"
          fill={themes.currentTheme.colors.iconColors.copyIcon}
        />
      </g>
    </svg>
  );
});

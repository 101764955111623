
import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { defaultTheme } from "../default/default-theme";
import { SportMainScalePalette } from "./sport-main-scale-palette";


export const sportTheme = {
    colors: {
        ...defaultTheme.colors,
        primary: SportMainScalePalette.Primary,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.Dark,
        outOfHoursContainerText: SportMainScalePalette.Primary,
        primaryButtonText: DefaultGreyScalePalette.Dark,
        primaryButtonBackground: SportMainScalePalette.Primary,
        primaryButtonBackgroundHover: "#FD9959",
        livePersonChipsColor : SportMainScalePalette.Primary,
        livePersonChipsBorderColor: SportMainScalePalette.Primary,
        livePersonSendButtonColor: SportMainScalePalette.Primary,
        iconColors: {
            ...defaultTheme.colors.iconColors
        } as IIconColors,
        helpCenterMainPageColors: {
          ...defaultTheme.colors.helpCenterMainPageColors,
          userName: SportMainScalePalette.Primary,
        } as IHelpCenterMainPageColors
    } as GeneralColors,
    images: {
        ...defaultTheme.images,
        amandaBubble: "Amanda Sport Bubble 55x55px",
        amandaBubbleWithNotification: "Amanda Sport Bubble Not 55x55px",
    },
    textKeys: {
        ...defaultTheme.textKeys,   
        faqButtonLabel: "AllCaps_Faq_Button_Label",
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        chatButtonLabel: "AllCaps_Chat_Button_Label",
        noLabel: "AllCaps_No_Label",
        yesLabel: "AllCaps_Yes_Label"
    },
};

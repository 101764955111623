import React, { useContext } from "react";
import { IChatContext } from "./chat-context.interface";

export const ChatContext = React.createContext<IChatContext>(null!);

export const ChatContextProvider = ChatContext.Provider;

export const useChatContext: () => IChatContext = () => {
  const context: IChatContext = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatcontext must be used in the root of the app");
  }
  return context;
};

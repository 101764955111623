import React from "react";
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const OpenLinkIconSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8092 3.10372V5.9091C11.8092 6.26557 12.0982 6.55456 12.4547 6.55456C12.8112 6.55456 13.1001 6.26557 13.1001 5.9091V1.54546V1.54545C13.1001 1.18899 12.8111 0.9 12.4547 0.9H8.09102C7.73455 0.9 7.44556 1.18899 7.44556 1.54546C7.44556 1.90193 7.73455 2.19092 8.09102 2.19092H10.8964L5.45283 7.63452C5.20075 7.8866 5.20075 8.29524 5.45282 8.54731C5.45282 8.54731 5.45282 8.54732 5.45283 8.54732M11.8092 3.10372L5.45283 8.54732M11.8092 3.10372L6.36564 8.54728M11.8092 3.10372L6.36564 8.54728M5.45283 8.54732L5.52354 8.47661M5.45283 8.54732C5.57881 8.67333 5.74418 8.73636 5.9092 8.73636C6.07421 8.73636 6.2396 8.67337 6.36562 8.5473L6.29491 8.47659M5.52354 8.47661C5.63001 8.58311 5.76961 8.63636 5.9092 8.63636C6.04879 8.63636 6.18841 8.58313 6.29491 8.47659M5.52354 8.47661C5.31051 8.26359 5.31051 7.91826 5.52354 7.70523L11.0378 2.19092L5.52354 8.47661ZM6.29491 8.47659L6.36564 8.54728M6.29491 8.47659L6.36564 8.54728"
        fill={themes.currentTheme.colors.iconColors.openLinkIcon}
        stroke={themes.currentTheme.colors.iconColors.openLinkIcon}
        stroke-width="0.2"
      />
      <path
        d="M10.2727 6.35436C9.91625 6.35436 9.62726 6.64335 9.62726 6.99982V11.8089H2.1909V4.37254H7C7.35647 4.37254 7.64546 4.08355 7.64546 3.72708C7.64546 3.3706 7.35647 3.08164 7 3.08164H1.54546C1.18899 3.08164 0.9 3.37063 0.9 3.7271V12.4544C0.9 12.8108 1.18899 13.0998 1.54546 13.0998H10.2727C10.6292 13.0998 10.9182 12.8108 10.9182 12.4544V6.99982C10.9182 6.64335 10.6292 6.35436 10.2727 6.35436Z"
        fill={themes.currentTheme.colors.iconColors.openLinkIcon}
        stroke={themes.currentTheme.colors.iconColors.openLinkIcon}
        stroke-width="0.2"
      />
    </svg>
    // </p>
  );
});

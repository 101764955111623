import React from "react";
import { TextKeys } from "./class/text-keys";
import { GeneralColors } from "./class/general-colors";
import { IWidgetTheme } from "./interface/widget-theme.interface";
import { IFontWeight } from "../interface/font-weight.interface";
import { GlobalStyle } from '../../global-styles';
import { IAssetResolver } from "../asset-resolver/asset-resolver.interface";
import { IAssets } from "../asset-resolver/make-assets/assets.interface";
import { makeAssets } from "../asset-resolver/make-assets/make-assets";

export function makeWidgetTheme(
    assetResolver: IAssetResolver, 
    colors: GeneralColors,
    assets?: Partial<IAssets>,
    fontWeight?: Partial<IFontWeight>,
    textKeys?: TextKeys,
    fontFamily?: string,
    ): IWidgetTheme {
    return {
        globalStyles: (<GlobalStyle/>),
        colors: colors,
        fontFamily: fontFamily ?? "'888-local'",
        textKeys: 
        {
            greetingMessage: textKeys?.greetingMessage ?? 'Greeting_Message',
            chatButtonLabel: textKeys?.chatButtonLabel ?? 'Chat_Button_Label',
            faqButtonLabel: textKeys?.faqButtonLabel ?? 'Faq_Button_Label',
            outOfHoursCloseLabel: textKeys?.outOfHoursCloseLabel ?? 'OutOfHours_Close_Label',
            hiMessage: textKeys?.hiMessage ?? "Hi_Message",
            noLabel: textKeys?.noLabel ?? "No_Label",
            yesLabel: textKeys?.yesLabel ?? "Yes_Label"
        },
        assets: { 
            ... makeAssets(assetResolver),//add the default
            ...assets
            },
        fontWeight: {
            normal: fontWeight?.normal ?? 'normal',
            bold: fontWeight?.bold ?? 'bold',
            weights: {
                greetingMessage: fontWeight?.weights?.greetingMessage ?? '500',
                faqTitle: fontWeight?.weights?.faqTitle ?? '600',
                faqQuestion: fontWeight?.weights?.faqQuestion ?? '400',
                outOfHoursCloseButton: fontWeight?.weights?.outOfHoursCloseButton ?? '900',
                outOfHoursText: fontWeight?.weights?.outOfHoursText ?? '400',
                outOfHoursHours: fontWeight?.weights?.outOfHoursHours ?? '400',
                outOfHoursCountry: fontWeight?.weights?.outOfHoursCountry ?? '400',
                urgentMessageText: fontWeight?.weights?.urgentMessageText ?? '600',
                urgentMessageCloseButton: fontWeight?.weights?.urgentMessageCloseButton ?? '900',
                livePersonAgentMessage: fontWeight?.weights?.livePersonAgentMessage ?? '400'
            },
            opacity: {
                outOfHoursText: fontWeight?.opacity?.outOfHoursText ?? '0.5',
                versionNumber: fontWeight?.opacity?.versionNumber ?? '0.4'
            },
            padding: {
                outOfHoursHours: fontWeight?.padding?.outOfHoursHours ?? "0px"
            },
            border: {
                agentAvatar: fontWeight?.border?.agentAvatar ?? "2px"
            }
        },
        fontSize: {
            xxxSmall: '1rem',
            xxSmall: '1.2rem',
            xSmall: '1.3rem',
            small: '1.4rem',
            medium: '1.6rem',
            large: '1.8rem',
            xLarge: '1.9rem',
            xxLarge: '2rem',
            xxxLarge: '2.4rem',
            xxxxLarge: '2.6rem',
            xxxxxLarge: '3rem',
            xxxxxxLarge: '3.4rem'
        },
    }
}
export enum FaqReportColumns {
  Summary = 2,
  Url = 6,
}

export const FAQ_REPORT_LIMIT = 5;

export const DOT_COM_BRANDS = [0, 1, 8];

export const OSVC_ENGLISH_CODE = 5;

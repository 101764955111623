import axios from "axios";
// import { AppClosed, AppInitRequest, AppInitResult, IEventData, OpenAppRequest } from "./models/event-data.interface";
import { IEventData } from "./models/event-data.interface";
import { LogFormatV2 } from "./models/log-format-v2.interface";
import { ServiceBase } from "./service-base";
import { IServiceFactory } from "./service-factory.interface";

import { env as currentEnv } from "../config";
import { Check } from "@sparkware/app-utils";
import { getErrorMessage } from "../utils/error";
import { IServiceBase } from "./service-base.interface";


export interface IClickStreamService extends IServiceBase {
    dispose(): void;
    send(level: string, message: string, eventData: any): void;

    // SendAppInitResult(params: AppInitResult): void;
    // SendAppInitRequest(params: AppInitRequest): void; 
    // SendAppClosed(params: AppClosed): void;
    // SendOpenAppReqest(params: OpenAppRequest): void;
    // SendAppMini(params: AppInitResult): void;
    // SendOpenApp(params: AppInitResult): void;
    // Send(message: string, params: IEventData, eventType: AnalyticsEventType): void;
}

export class ClickStreamService extends ServiceBase implements IClickStreamService {
    constructor(services: IServiceFactory) {
        super(services);

        this.environment = currentEnv && currentEnv == "prod" ? "production" : currentEnv;
        this.environment = currentEnv && currentEnv == "stage" ? "staging" : currentEnv;
    }

    readonly environment;


    getData(level: string, message: string, eventData: any) {
        let correlationId = Check.isEmpty(this.services.chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID) ?
            this.services.chatData.analyticsEventData.correlationId :
            this.services.chatData.chatInitParameters?.applicationContext.launchInfo.businessCorrelationID;

        return new LogFormatV2({
            eventHeader: {
                correlationId: correlationId,
                level,
                environment: this.environment,
                target: "core-cr-widget-logs",
                logVersionFormat: "2",
                // serverDateTime: new Date(Date.now()).toISOString(),
                clientDateTime: new Date(Date.now()).toISOString(),
                eventDateTime: new Date(Date.now()).toISOString(),
                message,
                eventType: {
                    name: "HTTPRequest",
                    type: ["Log"]
                }
            },
            sessionData: {},
            userData: {
                cid: this.services.chatData.playerData?.cid ? this.services.chatData.playerData?.cid : eventData?.data?.playerId,
                brandID: this.services.chatData.chatInitParameters?.applicationContext.brandID,
                brand: ""
            },
            eventData: {
                "HTTPRequest": eventData
            },
            message: message,
            fields: {
                env: this.environment
            },
            correlationId: correlationId
        });
    }

    async send(level: string, message: string, eventData: any) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // console.log(this.getData(level, message, eventData));
        try {

            await axios.post(this.services.chatData.environmentSettings.clickstreamUrl, this.getData(level, message, eventData), config)

        } catch (ex) {
            console.error(getErrorMessage(ex))
        }
    }

    // Send(message: string, params: IEventData, eventType: AnalyticsEventType) {
    //     switch (eventType) {
    //         case AnalyticsEventType.AppClosed:
    //             this.SendAppClosed(params as AppClosed);
    //             break;
    //         case AnalyticsEventType.AppInit:
    //             this.SendAppInitRequest(params as AppInitRequest);
    //             break;

    //         case AnalyticsEventType.AppMini:
    //             this.SendAppMini(params as AppInitResult);
    //             break;

    //         case AnalyticsEventType.OpenApp:
    //             this.SendOpenApp(params as AppInitResult);
    //             break;

    //         default:
    //     }
    // }

    // SendAppClosed(params: AppClosed): void {
    //     console.log(params);
    //     //axios.post(process.env.REACT_APP_CLICKSTREAM_URL ? "https://dmzls-dev.safe-installation.com/" : "", params);
    // }

    // SendAppInitResult(params: AppInitResult): void {
    //     throw new Error("Method not implemented.");
    // }
    // SendAppInitRequest(params: AppInitRequest): void {
    //     throw new Error("Method not implemented.");
    // }

    // SendOpenAppReqest(params: OpenAppRequest): void {
    //     throw new Error("Method not implemented.");
    // }
    // SendAppMini(params: AppInitResult): void {
    //     throw new Error("Method not implemented.");
    // }

    // SendOpenApp(params: AppInitResult): void {
    //     throw new Error("Method not implemented.");
    // }
}
import { computed, makeObservable, observable } from "mobx";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";


export interface ILoadingIndicatorService {
    show(): void;
    hide(): void;
}


export class LoadingIndictatorService extends ServiceBase implements ILoadingIndicatorService {

    constructor(services: IServiceFactory){
        super(services)
        
        makeObservable(this, {
            isLoading: observable.ref
        })
    }

    isLoading: boolean = false;

    // get isLoading(): boolean {
    //     if(!this.services.chatSettings.settings){
    //         return false;
    //     }
        

    //     return true;
    // }

    show() {
        this.services.logger.debug("Loading indicator shown,  @isLoading: ", {isLoading: this.isLoading}); 
        this.isLoading = true;
    }

    hide() {
        this.services.logger.debug("Loading indicator hidden,  @isLoading: ", {isLoading: this.isLoading}); 
        this.isLoading = false;
    }
}
import { Check } from "@sparkware/app-utils";
import {
  AppIdentifiers,
  IComponentInitParams,
  ITopicSubscription,
} from "@sparkware/uc-sdk-core";
import { useChatContext } from "../services/contexts/chat-context";
import { ServiceFactory } from "../services/service-factory";
import { IServiceFactory } from "../services/service-factory.interface";
import { CHAT_APP_ID, CHAT_BUBBLE_APP_ID, CHAT_BUBBLE_TOPIC_HEADERS } from "../types/global";
import { clearHtmlElementContent } from "../utils/utility";
import { IChatBubbleControler } from "./chat-bubble.controller.interface";

export class ChatBubblerController implements IChatBubbleControler {
  private _bubbleElement: HTMLDivElement | null = null;
  componentInitParams?: IComponentInitParams;
  private _subscriptions: ITopicSubscription[] = [];
  private _dotIndicatorWasAdded: boolean = false;
  private _serviceFactory?: IServiceFactory;
  private keepLookingForNewMessages = false;

  constructor(private readonly _document: HTMLDocument) {}

  show(initParams: IComponentInitParams): void {
    if (Check.isNullOrUndefined(this._serviceFactory)) {
      this._serviceFactory = new ServiceFactory(
        initParams.messageBrokerChannels
      );
    }
    const { logger, themes } = this._serviceFactory;

    logger.debug("Input properties: " + JSON.stringify(initParams));

    const { brandID, subBrandID } =
      initParams?.componentData?.appData?.appData ??
      initParams?.componentData?.appData;
    // To be refactored. Casino uses 'initParams?.componentData?.appData'
    // All others use 'initParams?.componentData?.appData?.appData'

    // init the chat themes in the bubble
    themes.init(brandID, subBrandID);

    clearHtmlElementContent(initParams?.containerID);

    this.componentInitParams = initParams;
    this._renderBubbleUI(this._serviceFactory!);
    this._subscribeToTopics(this._serviceFactory!);

    if (document.getElementsByClassName("lpc_transcript")?.length) {
      const messageContainer =
        document.getElementsByClassName("lpc_transcript")[0];
      const initialMessageNumber = messageContainer.childNodes.length;

      this.keepLookingForNewMessages = true;
      setTimeout(
        () => this._lookForNewMessages(initialMessageNumber, messageContainer),
        1000
      );
    }
  }

  _lookForNewMessages(initialMessageNumber: number, messageContainer: Element) {
    if (initialMessageNumber == messageContainer.childNodes.length) {
      setTimeout(
        () => this._lookForNewMessages(initialMessageNumber, messageContainer),
        1000
      );
      return;
    }

    this._serviceFactory?.messageBroker.chatActivity(CHAT_APP_ID, 1);
  }

  remove() {
    this.keepLookingForNewMessages = true;
    this._bubbleElement?.remove();
    this._bubbleElement = null;
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  private _renderBubbleUI(serviceFactory: IServiceFactory) {
    this._dotIndicatorWasAdded = false;
    this._bubbleElement = this._document.createElement("div");
    const imageElement = this._document.createElement("img");

    const bubbleContainer = this._document.getElementById(
      this.componentInitParams?.containerID!
    );
    const bubbleElement = this._bubbleElement;

    imageElement.style.width = "77px";
    imageElement.style.height = "80px";
    imageElement.style.cursor = "pointer";
    imageElement.src =
      serviceFactory.themes.currentTheme.assets.images.minimize_icon_2x_png!.toString();

    imageElement.onclick = this._onBubbleClick;
    this._bubbleElement.appendChild(imageElement);
    bubbleContainer?.appendChild(bubbleElement);
  }

  private _subscribeToTopics(serviceFactory: IServiceFactory) {
    this._subscriptions.push(
      this.componentInitParams?.messageBrokerChannels.customerRelations.topics.chatActivity.subscribe(
        (payload) => {
          // messages have arrived
          if (this._dotIndicatorWasAdded) return;

          this._bubbleElement &&
            this._bubbleElement.firstElementChild?.setAttribute(
              "src",
              serviceFactory.themes.currentTheme.assets.images.minimized_new_message_icon_2x_png!.toString()
            );
          this._dotIndicatorWasAdded = true;
        }
      )!
    );

    this._subscriptions.push(
      this.componentInitParams?.messageBrokerChannels.session.topics.appInitFailed.subscribe(
        (payload) => {
          if (payload.appID == CHAT_APP_ID || payload.appID == CHAT_BUBBLE_APP_ID) {
            clearHtmlElementContent(this.componentInitParams?.containerID) }
        })!
    );
  }

  private _onBubbleClick = () => {
    this.componentInitParams?.messageBrokerChannels.session.topics.appMaximized.publish(
      CHAT_BUBBLE_TOPIC_HEADERS,
      {
        appID: AppIdentifiers.CRChat,
      }
    );

    this._serviceFactory?.logger.info("App maximized.");
  };
}

import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const ChatIconHoverSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.831"
      height="15.831"
      viewBox="0 0 15.831 15.831"
    >
      <g
        id="_Chat_default_state"
        data-name=" Chat default state"
        transform="translate(0.25 0.25)"
      >
        <g id="chat">
          <path
            id="Path_2820"
            data-name="Path 2820"
            d="M992.041,913.021a.208.208,0,0,1,.086.018v11.037a.207.207,0,0,1-.086.018H983.29l-.205.147a8.962,8.962,0,0,0-1.482,1.519,6.868,6.868,0,0,1-.868.934l-.018.012c0-.558-.011-1.455-.019-1.86l-.015-.752h-2.528a.206.206,0,0,1-.087-.018V913.038a.206.206,0,0,1,.087-.018h13.885m0-.767H978.156a.808.808,0,0,0-.868.724v11.158a.808.808,0,0,0,.868.724h1.762c.011.53.02,1.909.02,2.247a.452.452,0,0,0,.5.479,1.366,1.366,0,0,0,.738-.262c.6-.407,1.541-1.874,2.365-2.464h8.5a.808.808,0,0,0,.868-.724V912.978a.808.808,0,0,0-.868-.724Z"
            transform="translate(-977.288 -912.254)"
            fill={themes.currentTheme.colors.iconColors.hover}
            stroke={themes.currentTheme.colors.iconColors.hover}
            stroke-width="0.5"
          />
          <g
            id="Group_42555"
            data-name="Group 42555"
            transform="translate(2.421 5.172)"
          >
            <ellipse
              id="Ellipse_481"
              data-name="Ellipse 481"
              cx="1.242"
              cy="1.247"
              rx="1.242"
              ry="1.247"
              fill={themes.currentTheme.colors.iconColors.hover}
            />
            <ellipse
              id="Ellipse_482"
              data-name="Ellipse 482"
              cx="1.242"
              cy="1.247"
              rx="1.242"
              ry="1.247"
              transform="translate(8.294)"
              fill={themes.currentTheme.colors.iconColors.hover}
            />
            <ellipse
              id="Ellipse_483"
              data-name="Ellipse 483"
              cx="1.242"
              cy="1.247"
              rx="1.242"
              ry="1.247"
              transform="translate(4.147)"
              fill={themes.currentTheme.colors.iconColors.hover}
            />
          </g>
        </g>
      </g>
    </svg>
  );
});

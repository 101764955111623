import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const FaqIconHoverSVG: FC = observer(() => {
  const { themes } = useChatContext().services;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.074"
      height="17.074"
      viewBox="0 0 17.074 17.074"
    >
      <g id="FAQ" transform="translate(0.25 0.25)">
        <g
          id="Group_42556"
          data-name="Group 42556"
          transform="translate(6.5 3.514)"
        >
          <path
            id="Path_2821"
            data-name="Path 2821"
            d="M923.332,922.955a.65.65,0,0,0-.467.183.58.58,0,0,0-.19.43.573.573,0,0,0,.19.435.659.659,0,0,0,.467.178.636.636,0,0,0,.456-.183.576.576,0,0,0,0-.855A.628.628,0,0,0,923.332,922.955Z"
            transform="translate(-921.535 -917.662)"
            fill={themes.currentTheme.colors.iconColors.default}
          />
          <path
            id="Path_2822"
            data-name="Path 2822"
            d="M922.532,919.789h0a.543.543,0,0,1-.506-.574v-.8a.486.486,0,0,1,.431-.519.766.766,0,0,0,.852-.821.717.717,0,0,0-.777-.674,1.652,1.652,0,0,0-.935.589.449.449,0,0,1-.611.055l-.012-.01a.616.616,0,0,1-.092-.863,2.216,2.216,0,0,1,1.626-.918,1.822,1.822,0,0,1,.712,3.495.281.281,0,0,0-.183.273v.193A.543.543,0,0,1,922.532,919.789Z"
            transform="translate(-920.746 -915.254)"
            fill={themes.currentTheme.colors.iconColors.hover}
          />
        </g>
        <path
          id="Path_2823"
          data-name="Path 2823"
          d="M929.4,913.083a.216.216,0,0,1,.092.019v11.932a.213.213,0,0,1-.092.019h-9.284l-.219.159a9.641,9.641,0,0,0-1.572,1.643,7.315,7.315,0,0,1-.92,1.009l-.019.013c0-.6-.012-1.573-.02-2.011l-.016-.813h-2.683a.217.217,0,0,1-.092-.019V913.1a.219.219,0,0,1,.092-.019H929.4m0-.829H914.667a.863.863,0,0,0-.921.782V925.1a.863.863,0,0,0,.921.782h1.87c.011.574.022,2.064.022,2.429a.484.484,0,0,0,.534.518,1.435,1.435,0,0,0,.783-.283c.638-.44,1.635-2.026,2.508-2.664H929.4a.863.863,0,0,0,.921-.782V913.036a.863.863,0,0,0-.921-.782Z"
          transform="translate(-913.746 -912.254)"
          fill={themes.currentTheme.colors.iconColors.hover}
          stroke={themes.currentTheme.colors.iconColors.hover}
          stroke-width="0.5"
        />
      </g>
    </svg>
  );
});

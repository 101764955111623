import { action, makeObservable, observable } from "mobx";
import { CHAT_APP_ID, CHAT_BUBBLE_APP_ID } from "../../types/global";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import { IChatBubbleService } from "./chat-bubble.service.interface";

export class ChatBubbleService extends ServiceBase implements IChatBubbleService {
    
    constructor(services: IServiceFactory) {
        super(services);
        makeObservable(this, {
            containerId: observable.ref,
            hide: action.bound,
            show: action.bound
        });

        this.services.messageBroker.subscribe(channels => {
            return channels.session.topics.appMaximized.subscribe(payload => {
                if(payload.appID === CHAT_APP_ID) {
                    this.hide();
                    channels.session.topics.appClosed.publish({
                        publisher: CHAT_APP_ID,
                    }, {
                        appID: CHAT_APP_ID
                    });
                }
            })
        });
    }

    containerId: string | null = null;


    hide(): void {
        this.services.logger.debug("Bubble hide with @cotainerId: ", {containerId: this.containerId}); 
        this.containerId = null;
    }

    show(containerId: string): void {
        this.services.logger.debug("Bubble shown with @cotainerId: ", {containerId: this.containerId}); 

        this.containerId = containerId;
    }

}
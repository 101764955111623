import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "./default-greyscale-palette";
import { DefaultMainScalePalette } from "./default-main-scale-palette";

export const defaultTheme = {
    colors: {
        primary: DefaultMainScalePalette.Primary,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.Dark,
        text: DefaultGreyScalePalette.White,
        secondaryText: DefaultGreyScalePalette.LightGrey,
        secondaryHeader: DefaultGreyScalePalette.Grey,
        mainWindowBackground: DefaultGreyScalePalette.LightDark,
        helpCenterBackground: DefaultGreyScalePalette.Dark,
        urgentMessageTitleBarBackground: DefaultGreyScalePalette.LightDark,
        urgentMessageBackground: DefaultGreyScalePalette.LightDark,
        faqUrgentMessageBackground: DefaultGreyScalePalette.LightDark,
        questionMarkBackground: DefaultGreyScalePalette.Grey,
        urgentMessageText: DefaultGreyScalePalette.White,
        outOfHoursBackground: DefaultGreyScalePalette.LightDark,
        outOfHoursContainerText: DefaultMainScalePalette.Primary,
        outOfHoursContainerBackground: DefaultGreyScalePalette.Dark,
        outOfHoursText: DefaultGreyScalePalette.White,
        outOfHoursButtonBackground: "#00ff00",
        outOfHoursButtonText: DefaultGreyScalePalette.LightDark,
        chatPageUrgentMessageBackground: DefaultGreyScalePalette.Grey,
        messageBackground: DefaultGreyScalePalette.MediumDark,
        popupBackground: DefaultGreyScalePalette.Dark,
        userMessageBackground: DefaultGreyScalePalette.LightGrey,
        helpCenterButtonsBackground: DefaultGreyScalePalette.Grey,
        outOfHoursBorder: DefaultGreyScalePalette.MediumGrey,
        buttonHover: DefaultGreyScalePalette.White,
        primaryButtonText: DefaultGreyScalePalette.Dark,
        primaryButtonBackground: DefaultMainScalePalette.Primary,
        primaryButtonBackgroundHover: DefaultMainScalePalette.Primary,
        secondaryButtonText: DefaultGreyScalePalette.LightGrey,
        secondaryButtonBackground: DefaultGreyScalePalette.Dark,
        confirmationDialogText: DefaultGreyScalePalette.LightGrey,
        buttonBorderColor: DefaultGreyScalePalette.Transparent,
        headerButtonsColor: DefaultGreyScalePalette.White,
        livePersonComposer: DefaultGreyScalePalette.LightDark,
        livePersonComposerText: DefaultGreyScalePalette.MediumDark,
        livePersonComposerTextBackgroundColor: DefaultGreyScalePalette.LightDark,
        livePersonChatBackgroundColor: DefaultGreyScalePalette.MediumDark,
        livePersonInputText: DefaultGreyScalePalette.White,
        livePersonAgentBackgroundMessageColor:  DefaultGreyScalePalette.LightDark,
        livePersonAgentMessageColor:  DefaultGreyScalePalette.White,
        livePersonVisitorBackgroundMessageColor: DefaultGreyScalePalette.LightGrey,
        livePersonVistorMessageBorderColor: DefaultGreyScalePalette.LightGrey,
        livePersonVisitorMessageColor: DefaultGreyScalePalette.MediumDark,
        livePersonTimestampColor: DefaultGreyScalePalette.LightGrey,
        livePersonNotificationColor: DefaultGreyScalePalette.MediumGrey,
        livePersonChipsColor : DefaultMainScalePalette.Primary,
        livePersonChipsHoverColor : DefaultGreyScalePalette.Dark,
        livePersonChipsBorderColor: DefaultMainScalePalette.Primary,
        livePersonSendButtonColor: DefaultMainScalePalette.Primary,
        chatTitle: DefaultGreyScalePalette.White,
        iconColors: {
            default: DefaultGreyScalePalette.LightGrey,
            disabledPrimary: DefaultGreyScalePalette.MediumGrey,
            disabledSecondary: DefaultGreyScalePalette.Dark,
            hover: DefaultGreyScalePalette.White,
            openLinkIcon: DefaultGreyScalePalette.LightGrey,
            faqSpacingLine: DefaultGreyScalePalette.MediumDark,
            copyIcon: DefaultGreyScalePalette.White
        },
        helpCenterMainPageColors: {
            greetingMessage: DefaultGreyScalePalette.White,
            userName: DefaultMainScalePalette.Primary,
            faqTitle: DefaultGreyScalePalette.White,
        }
    } as GeneralColors,
    images: {
        amandaBubble: "Amanda Default Bubble 55x55px",
        amandaBubbleWithNotification: "Amanda Default Bubble Not 55x55px",
        infoIcon: "info-icon",
        infoIconHover: "info-icon-hover"
    },
    textKeys: {
        greetingMessage: "Greeting_Message",
        chatButtonLabel: "Chat_Button_Label",
        faqButtonLabel: "Faq_Button_Label",
        outOfHoursCloseLabel: "OutOfHours_Close_Label",
        hiMessage: "Hi_Message",
        noLabel: "No_Label",
        yesLabel: "Yes_Label"
    },
    fontWeight: {
        normal: '600',
        weights: {
            greetingMessage: '500',
            faqTitle: '600',
            faqQuestion: '400',
            outOfHoursCloseButton: '900',
            outOfHoursText: '400',
            outOfHoursHours: '400',
            outOfHoursCountry: '400',
            urgentMessageText: '600',
            urgentMessageCloseButton: '900',
            livePersonAgentMessage: '400'
        },
        opacity: {
            outOfHoursText: '0.5',
            versionNumber: '0.4'
        },
        padding: {
            outOfHoursHours: "0px"
        },
        border: {
            agentAvatar: "2px"
        }
    }
};

import React from 'react';
import { observer } from "mobx-react";
import { FC } from "react";
import { useChatContext } from "../../services/contexts/chat-context";

export const AttatchIconSVG: FC = observer(() => {
    return (
        <svg width="20" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="attach_file_add_FILL0_wght400_GRAD0_opsz48 1">
                <path
                    id="Vector"
                    d="M8.625 16.582C7.4875 16.582 6.51562 16.1914 5.70937 15.4102C4.90312 14.6289 4.5 13.6695 4.5 12.532V4.46953C4.5 3.67547 4.78457 2.9957 5.35371 2.43022C5.92284 1.86476 6.60702 1.58203 7.40625 1.58203C8.21875 1.58203 8.90625 1.86328 9.46875 2.42578C10.0312 2.98828 10.3125 3.67578 10.3125 4.48828V10.7508H9.5625V4.46953C9.5625 3.87103 9.35444 3.36516 8.93833 2.95191C8.52223 2.53866 8.01286 2.33203 7.41021 2.33203C6.80757 2.33203 6.29687 2.53866 5.87812 2.95191C5.45937 3.36516 5.25 3.87103 5.25 4.46953V12.5695C5.25 13.482 5.58047 14.2539 6.24141 14.8852C6.90234 15.5164 7.69687 15.832 8.625 15.832C9.10077 15.832 9.54784 15.7414 9.96621 15.5602C10.3846 15.3789 10.75 15.132 11.0625 14.8195V15.7945C10.7125 16.0445 10.3344 16.2383 9.92812 16.3758C9.52187 16.5133 9.0875 16.582 8.625 16.582ZM12.1875 15.6445V13.3945H9.9375V12.2695H12.1875V10.0195H13.3125V12.2695H15.5625V13.3945H13.3125V15.6445H12.1875ZM8.625 12.832V13.582C8.15 13.582 7.75 13.4039 7.425 13.0477C7.1 12.6914 6.9375 12.2695 6.9375 11.782V4.43203H7.6875V11.8383C7.6875 12.1133 7.77734 12.3477 7.95703 12.5414C8.13672 12.7352 8.35937 12.832 8.625 12.832ZM12 8.38828V4.43203H12.75V8.38828H12Z"
                    fill="#FFFFFF" //Fill color is inherited from button
                />
            </g>
        </svg>
    );
});



import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.Regular.toString()});
    font-weight: normal;
}

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.Bold.toString()});
    font-weight: bold;
}

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.Medium.toString()});
    font-weight: 500;
}

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.Thin.toString()});
    font-weight: 100;
}

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.Black.toString()});
    font-weight: 900;
}

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.DemiBold.toString()});
    font-weight: 600;
}

@font-face {
    font-family: '888-local';
    src: url(${(props: any) => props.theme.assets.fonts.Light.toString()});
    font-weight: 200;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_Regular.toString()});
    font-weight: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_Bold.toString()});
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_Medium.toString()});
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_Thin.toString()});
    font-weight: 100;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_Black.toString()});
    font-weight: 900;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_DemiBold.toString()});
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url(${(props: any) => props.theme.assets.fonts.Montserrat_Light.toString()});
    font-weight: 200;
}

.oda-chat-title{
    color: #333;
    font-size: 16px;
}

.oda-chat-logo {
    display: none;
}

.oda-chat-widget .oda-chat-header{
    height: 50px;
}


.oda-chat-widget .oda-chat-header-info-wrapper{
    margin: 0 !important;
    width: auto;
}

.oda-chat-header-custom-element{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.oda-chat-widget .oda-chat-left .oda-chat-message-bubble, .oda-chat-widget .oda-chat-right .oda-chat-message-bubble{
    font-size: 12px !important;
    padding: 6px 11px;
    font-family: '888-local' !important;
    font-weight: ${(props: any) => props.theme.fontWeight.normal}
}

.oda-chat-message-bubble{
    padding: 8px 13px;
}

.oda-chat-widget .oda-chat-left .oda-chat-message-bubble {
    border-radius: 5px 20px 20px 20px;
}

.oda-chat-widget .oda-chat-right .oda-chat-message-bubble {
    border-radius: 20px 5px 20px 20px;
}

.oda-chat-widget .oda-chat-message-date{
    font-size: 8px;
    opacity: .5;
    width: 32px;
    color: #fff;
}

.oda-chat-widget .oda-chat-left .oda-chat-message-date {
    left: 0px;
    color: ${(props: any) => props.theme.colors.text};
    margin-left: 60px;
}

.oda-chat-widget .oda-chat-right .oda-chat-message-date {
    right: 0px;
    color: #fff;
}

#oda-chat-share-audio, #oda-chat-share-location{
    display: none;
}

.oda-chat-conversation{
    font-family: '888-local' !important;
}

.oda-chat-conversation .oda-chat-embed-scroll-top{
    padding-top: 10px;
}

.oda-chat-open{
    height: 100%;
    width: 100%;
    position:relative;
}

.oda-chat-open .oda-chat-widget{
    max-height: 100%;
    max-width: 100%;
    position:absolute !important;
    bottom: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
}

.oda-chat-widget .oda-chat-embed-sticky-bottom{
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #232323;
}

.oda-chat-footer .oda-chat-user-input{
    border: 0;
    font-family: '888-local';
    font-size: 14px;
}

.oda-chat-footer .oda-chat-user-input:focus{
    background-color: ${(props: any) => props.theme.colors.messageBackground}
    box-shadow: none;
}

.oda-chat-footer .oda-chat-user-input::placeholder, .oda-chat-footer .oda-chat-user-input:-ms-input-placeholder{
    opacity: 0.5;
}

.oda-chat-left .oda-chat-icon-wrapper{
    height: 50px;
    min-width: 50px;
    width: 50px;
    align-self: auto;
}

.oda-chat-left .oda-chat-icon-wrapper .oda-chat-message-icon {
    height: 50px;
    max-height: 50px;
    max-width: 50px;
    width: 50px;
}

.oda-chat-conversation::-webkit-scrollbar, .oda-chat-user-input::-webkit-scrollbar{
    width: 3px;
}
  
.oda-chat-conversation::-webkit-scrollbar-thumb, .oda-chat-user-input::-webkit-scrollbar-thumb{
    background: #717171;
    border-radius: 3px;
}

.oda-chat-footer .oda-chat-footer-button:active, .oda-chat-widget .oda-chat-round:active svg {
    opacity: 0.5;
    outline: none;
}


.oda-chat-widget .oda-chat-content-wrapper{
    overflow-x: initial;
}

.oda-chat-widget .oda-chat-message-content{
    letter-spacing: 0.84px;
}

.oda-chat-widget .oda-chat-round, .oda-chat-widget .oda-chat-round:hover:enabled, .oda-chat-widget .oda-chat-round:focus:enabled {
    background-color: #000;
}

.oda-chat-widget .oda-chat-round:focus:enabled{
    outline: none;
}

.oda-chat-widget .oda-chat-round svg, .oda-chat-widget .oda-chat-round:hover svg, .oda-chat-widget .oda-chat-round:focus svg {
    fill: ${(props: any) => props.theme.colors.primary};
}

.oda-chat-widget .oda-chat-content-wrapper.oda-chat-with-icon {
    max-width: 78%;
}

.oda-chat-widget .oda-chat-card {
    color: #FFFFFF;
    background-color: #171717;
    border-radius: 5px 20px 20px 20px;
    padding: 0px;
}

.oda-chat-widget .oda-chat-card-title{
    margin: 10px 15px;
    font-weight: bold;
}

.oda-chat-widget .oda-chat-card-description{
    margin: 17px 17px;
}

.oda-chat-widget .oda-chat-card-title, .oda-chat-widget .oda-chat-card-description {
    color: #FFFFFF;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.84px;
}

.oda-chat-widget .oda-chat-message-actions .oda-chat-action-postback, .oda-chat-widget .oda-chat-card-actions .oda-chat-action-postback, .oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback {
    background-color: transparent;
    border: 2px solid ${(props: any) => props.theme.colors.primary};
    color: ${(props: any) => props.theme.colors.primary};
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.98px;
    padding: 6px 6px;
}

.oda-chat-widget .oda-chat-message-actions .oda-chat-action-postback div, .oda-chat-widget .oda-chat-card-actions .oda-chat-action-postback div, .oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback div {
    padding-top: 2px;
    font-family: "888-local"!important;
}

.oda-chat-widget .oda-chat-message-actions .oda-chat-action-postback:focus:enabled, .oda-chat-widget .oda-chat-card-actions .oda-chat-action-postback:focus:enabled, .oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback:focus:enabled {
    background-color: transparent;
    outline: none;
}

.oda-chat-widget .oda-chat-card-image {
    height: 36px;
    width: 95px;
}

.oda-chat-widget .oda-chat-message-actions .oda-chat-action-postback:hover:enabled, .oda-chat-widget .oda-chat-card-actions .oda-chat-action-postback:hover:enabled, .oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback:hover:enabled {
    background-color: ${(props: any) => props.theme.colors.primary};
    color: ${(props: any) => props.theme.colors.primaryHeader};
}

.oda-chat-widget .oda-chat-message-actions button.oda-chat-disabled, .oda-chat-widget .oda-chat-card-actions button.oda-chat-disabled, .oda-chat-widget .oda-chat-message-global-actions button.oda-chat-disabled {
    background: transparent;
    border-color: ${(props: any) => props.theme.colors.primary};
    color: ${(props: any) => props.theme.colors.primary};
    opacity: .5;
}

.oda-chat-widget .oda-chat-message-actions button.oda-chat-disabled:hover, .oda-chat-widget .oda-chat-card-actions button.oda-chat-disabled:hover, .oda-chat-widget .oda-chat-message-global-actions button.oda-chat-disabled:hover {
    border-color: ${(props: any) => props.theme.colors.primary};
    color: ${(props: any) => props.theme.colors.primary};
    background: transparent;
}

.oda-chat-widget .oda-chat-message-actions .oda-chat-action-postback:active:enabled, .oda-chat-widget .oda-chat-card-actions .oda-chat-action-postback:active:enabled, .oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback:active:enabled {
    background-color: ${(props: any) => props.theme.colors.primary};
    color: #000;
    outline: none;
}

.oda-chat-widget .oda-chat-autocomplete-items{
    border: none;
    max-height: none;
    margin: 0 10px;
}

.oda-chat-widget .oda-chat-autocomplete-items div{
    padding: 8px 10px;
    margin: 9px;
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    font-size: 14px;
    letter-spacing: 0.98px;
    font-weight: normal;
    min-height: unset;
    line-height: 16px;
}

.oda-chat-widget .oda-chat-autocomplete-items div:hover{
    border: 2px solid ${(props: any) => props.theme.colors.primary};
    background-color: #ffffff;
}

.oda-chat-widget .oda-chat-autocomplete-items div:active{
    border: 2px solid #e9e9e9;
    background-color: #e9e9e9;
}

.oda-chat-widget .oda-chat-message-content a, .oda-chat-widget .oda-chat-message-content a:hover, .oda-chat-widget .oda-chat-message-content a:active {
    color: #0077EE;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.oda-chat-widget .oda-chat-message-content a:visited {
    color: #8F30CB;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.oda-chat-widget .oda-chat-message-global-actions {
    flex-direction: row;
    align-items: center;
    justify-items: left;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 7px;
}

.oda-chat-widget .oda-chat-message-global-actions.oda-chat-has-message-icon {
    margin-left: 58px;
    max-width: 350px;
    padding-left: 15px;
    margin-right: 20px;
}

.oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback:first-of-type {
    margin-top: auto;
}

.oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback:last-of-type {
    margin-bottom: auto;
}

.oda-chat-widget .oda-chat-message-global-actions .oda-chat-action-postback {
    flex: none;
    margin-right: 10px;
}

/* Survey */
.oda-chat-webview-header {
    background-color: ${(props: any) => props.theme.colors.secondaryHeader};
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid ${(props: any) =>
      props.theme.colors.outOfHoursBorder};
}

.oda-chat-webview-header .oda-chat-webview-title {
    color:  ${(props: any) => props.theme.colors.userMessageBackground};
    margin: 0 auto;
    font-size: 14px;
    font-family: '888-local';
}

.oda-chat-webview-header .oda-chat-webview-button-close {
    margin-right: 16px;
    height: 18px;
    width: 18px;
    background: ${(props: any) => props.theme.colors.secondaryHeader} url("${(
  props: any
) => props.theme.assets.images.close_button_png}") no-repeat;
}

 .oda-chat-webview-header .oda-chat-header-icon svg {
    fill: ${(props: any) => props.theme.colors.mainWindowBackground};
}

.oda-chat-webview-header .oda-chat-header-icon:hover svg {
    fill: ${(props: any) => props.theme.colors.mainWindowBackground};
}

iframe.oda-chat-webview {
    background-color: ${(props: any) => props.theme.colors.secondaryHeader}; 
}

.oda-chat-webview-container {
    background-color:  ${(props: any) =>
      props.theme.colors.mainWindowBackground};
}

/* GWB Confetti */
#bonusAmount {
    display: block;
    font-size: 3em;
    margin: 0.5em auto 0.6em auto;
    text-align: center;
}

#bonus-confetti {
    position: absolute;
    z-index: 99999;
    top: -200px;
    left: -100px;
    width: 570px;
    height: 1000px;
}
/* Agent Transfer Animation */
#transfer-animation-container {
    background:  ${(props: any) => props.theme.colors.mainWindowBackground};
    overflow-y: hidden; 
    max-height: 0px;
    
}
#transfer-animation-container.visible {
    max-height: 160px;
    padding-bottom: 10px;
}


#lpChat .lp_maximized.lpc_desktop.lpc_window_maximized,
    #lpChat .lp_maximized.lpc_mobile.lpc_window_maximized, 
    #lpChat .lp_maximized.lpc_tablet.lpc_window_maximized
{
    position: relative;
    width: 100%;
    height: 100%;
    left: 0px !important;
    top: 0px !important;
    margin-bottom: 0px !important;
    max-height: 100% !important;
    z-index: 999;
}

.lp_desktop .lp-window-root,
.lp_mobile .lp-window-root,
.lp_tablet .lp-window-root
{
    height: 100%;
}

.lp_desktop, .lp_mobile, lp_tablet {
    display: none;
    height: 100%;
}

.lp_visible {
    display: block;
}

.lpc_message__text {
    font-size: 12px !important;
    line-height: 18px !important;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-weight: ${(props: any) => props.theme.fontWeight};
    border-color: ${(props: any) => props.theme.colors.livePerson} !important;
    color: ${(props: any) => props.theme.colors.text} !important;
}

.lpc_message.lpc_message_agent
{
    background-color: ${(props: any) => props.theme.colors.livePersonAgentBackgroundMessageColor} !important;
}
.lpc_message__text_agent {
    color: ${(props: any) => props.theme.colors.livePersonAgentMessageColor} !important;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.livePersonAgentMessage} !important;
}

.lpc_message__text_visitor {
    color: ${(props: any) => props.theme.colors.livePersonVisitorMessageColor} !important;
} 

.lpc_message.lpc_message_visitor{
    background-color: ${(props: any) => props.theme.colors.livePersonVisitorBackgroundMessageColor} !important;
    border: 1px solid ${(props:any) => props.theme.colors.livePersonVistorMessageBorderColor} !important;
}

.agent_avatar {
    height: 48px !important;
    width: 48px !important;
    border-style: solid !important;
    border-width: ${(props: any) => props.theme.fontWeight.border.agentAvatar} !important;
    border-color: ${(props: any) => props.theme.colors.primary} !important;
    border-radius: 24px !important;
}

.chips-item {
    color: ${(props: any) => props.theme.colors.livePersonChipsColor} !important;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    border-color: ${(props: any) => props.theme.colors.livePersonChipsBorderColor} !important;
    transition: 0.1s;
    padding: 12px 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px !important;
    border-radius: 44px !important;
}

.chips-item:active, .chips-item:hover {
    color: ${(props: any) => props.theme.colors.livePersonChipsHoverColor} !important;;
    background: ${(props: any) => props.theme.colors.primary} !important;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    border-color: ${(props: any) => props.theme.colors.primary} !important;
    transition: 0.3s;
}


.lpc_message-area__timestamp {
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;

}

.lp-window-root .lp_chat_line_wrapper.lp_agent.lp_bubble_style.avatar-padding .lp_time
{
    left: 50px !important;
}

.lpc_message-area__indicator {
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-style: normal;
    font-weight: 500;
}

.root {
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.lp_actions_button {
    position: revert !important;
}

.lpview_form_textarea .lpc_composer__text-area
{
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    color: ${(props: any) => props.theme.colors.text} !important;
}

.lpc_composer__text-area
{
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    color: ${(props: any) => props.theme.colors.text} !important;
}

.lpc_composer
{
    border: none !important;
    padding: 0px !important;
    gap: 43px !important;
    display: inline-flex !important;

    position: relative !important;
    background:  ${(props: any) => props.theme.colors.livePersonComposer} !important; !important;
    width: 400px;
}

.lp_location_bottom
{
    height: 50px !important;
    width: 100% !important;
}

.lp_bottom_area
{
    background-color: ${(props: any) => props.theme.colors.livePersonComposerTextBackgroundColor} !important;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 15px !important;
}

.lpview_bottom_container
{
    display: inline-flex !important;
    align-items: center !important;
    width: 96% !important;
    height: 50px !important;
}

.lp_paper_plane_button
{
    display: none !important;
    bottom: 12px !important;
    top: 12px !important;
}

.lpc_transcript_region {
      background-color: ${(props: any) => props.theme.colors.livePersonChatBackgroundColor} !important;
}

.lp_location_center
{
    background-color: ${(props: any) => props.theme.colors.livePersonChatBackgroundColor} !important;
    height: calc(100% - 50px) !important;
}

.lp_header.lpc_maximized-header,
.lp_lpview_mp.lp_dialog_container.lpc_confirmation-dialog {
   background-color: ${(props: any) => props.theme.colors.livePersonChatBackgroundColor} !important;
}

.lp_lpview_agent_is_typing
{
    font-family: ${(props: any) => props.theme.fontFamily} !important;
}

.lp_agent_is_typing
{
    font-family: ${(props: any) => props.theme.fontFamily} !important;
}

.lpc_typing-indication .lpc_typing-indication_agent
{
    font-family: ${(props: any) => props.theme.fontFamily} !important;
}

.lp_header-content-wrapper
{
    display: none;
}

.lp_header
{
    display: none;
}

.lp_main, .lpc_body {
    top: 50px !important;

    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: #CCCCCC;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #232323;
        border-radius: 5px;
        width: 4px;
        background-clip: content-box;
        border: 3px solid transparent;
    }

    ::-webkit-scrollbar-button:single-button:vertical:decrement {
        height: 12px;
        width: 16px;
        background-position: center 4px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    ::-webkit-scrollbar-button:single-button:vertical:increment {
        height: 12px;
        width: 16px;
        background-position: center 2px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
    }
}

.lp_center_div_inner_content
{
    font-family: ${(props: any) => props.theme.fontFamily} !important;
}

button.lp_cancel_button, .lp_cancel_button, .lpc_confirmation-dialog__cancel-button
{
    border-radius: 44px !important;
    background-color: ${(props: any) => props.theme.colors.primary} !important;
    color: ${(props: any) => props.theme.colors.primaryButtonText} !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 14px !important;
    border: 2px solid !important;
    border-color: ${(props: any) => props.theme.colors.primary} !important;
}

button.lp_confirm_button, .lp_confirm_button, .lpc_confirmation-dialog__confirm-button
{
    border-radius: 44px !important;
    background-color: ${(props: any) => props.theme.colors.secondaryButtonBackground} !important;
    color: ${(props: any) => props.theme.colors.secondaryButtonText} !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 14px !important;
    border: 2px solid ${(props: any) => props.theme.colors.secondaryButtonText} !important;
}

.LPMcontainer {
    display: none !important;
}

.lp-json-pollock-layout, .lp-json-pollock-layout-vertical, .lpc_card, .lpc_card_vertical
{
    background-color: #232323 !important;
    border-radius: 5px;
}

.lp-json-pollock-element-text, .lpc_card__text
{
    color: #FFFFFF !important;
}

.lp_input_area, .lpc_composer__text-area-wrapper
{
    width: 100% !important;    
    margin: 5px 16px 5px 16px !important;
    overflow-y: hidden !important;
    /*
    height: 40px !important;
    padding: 0px !important;    
    */
}

/*.lp-window-root .lp_input_area .lp_expandable_textarea
{
    height: 40px;
    padding: 12px 0px;    
}*/

.lpview_form_textarea.lpc_composer__text-area.lp_expandable_textarea.lp_ltr
{    
    color: ${(props: any) => props.theme.colors.text} !important;
    font-weight: 600px !important; 
    overflow-y: clip !important;
}

.lpview_form_textarea.lpc_composer__text-area.lp_expandable_textarea.lp_ltr {
    background-color: ${(props: any) => props.theme.colors.livePersonComposerTextBackgroundColor} !important;
}

.lpview_form_textarea.lpc_composer__text-area {
    max-height: 45px !important;
}

textarea.lpview_form_textarea.lpc_composer__text-area.lpc_desktop.lp_expandable_textarea.lp_ltr, 
textarea.lpview_form_textarea.lpc_composer__text-area.lpc_mobile.lp_expandable_textarea.lp_ltr,
textarea.lpview_form_textarea.lpc_composer__text-area.lpc_tablet.lp_expandable_textarea.lp_ltr {
    color: ${(props: any) => props.theme.colors.livePersonInputText} !important;
    font-weight: 600 !important;
}

.lpc_message.lpc_message_agent
{
    background-color: ${(props: any) => props.theme.colors.livePersonAgentBackgroundMessageColor} !important;
}

.lp-window-root .lp_chat_line_wrapper.lp_grouped
{
    padding: 0px 10px 6px 16px !important;
}

.lp_time, .lp_time_side, .lpc_message-area__side-timestamp, .lpc_message-area__side-timestamp_agent, .lpc_message-area__side-timestamp_avatar-shown
{
    display: none !important;
    font-size: 0px;
    color:transparent;
}



div.lpc_layout.lpc_layout_maximized {
    background-color: ${(props: any) => props.theme.colors.livePersonChatBackgroundColor} !important;
}

.lpc_message
{
    padding: 8px 16px 8px 16p !important;
    border: 0px !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 22px;
}

.lp_time_side_wrapper
{
    display: none !important;
}

.lp_time_bottom, .lpc_message-area__timestamp, .lpc_message-area__timestamp_agent, .lpc_message-area__timestamp_avatar-shown
{
    display: block !important;
    padding-top: 10px; !important;
    color: ${(props: any) => props.theme.colors.livePersonTimestampColor} !important;
}


svg.lp-state-visual.lp-read-notification > g > g,
svg.lp-state-visual.lp-sent-notification > g > g  {
    //fill: ${(props: any) => props.theme.colors.livePersonNotificationColor} !important;
    stroke: ${(props: any) => props.theme.colors.livePersonNotificationColor} !important;
}

.lp_file_button
{
    margin-left: 10px;
    display: contents;
}

.lp_send_button
{
    display: contents !important;
    fill: ${(props: any) => props.theme.colors.livePersonSendButtonColor} !important;
}

.lp_send_button:disabled
{
    display: contents !important;
    fill: ${(props: any) => props.theme.colors.iconColors.disabledPrimary} !important;
}

.lp_preview_caption
{
    padding: 0px !important;
}

.lp_image_preview_background
{
    bottom: 0% !important;
    height: 96% !important;
    top: auto !important;
}

.lp-window-root .lp_file-icon svg path {
    fill: ${(props: any) => props.theme.colors.livePersonSendButtonColor} !important;
}

.lp-window-root .lp_send-icon svg path {
    fill: inherit !important;
}

.send_file_icon
{
    fill: inherit;
}

.lpc_confirmation-dialog__title {
    color: ${(props: any) => props.theme.colors.confirmationDialogText} !important;
}
`
import { IAssetResolver } from "../asset-resolver.interface";
import { Asset } from "../asset/asset";
import { IAssets } from "./assets.interface";

export function makeAsset(assetName: string, assetResolver: IAssetResolver): string{
    return new Asset(assetName, assetResolver).toString();
}
export function makeAssets(assetResolver: IAssetResolver): IAssets{
    return {
        fonts: {
            Regular: new Asset('fonts/888-Regular.otf', assetResolver),
            Bold: new Asset('fonts/888-Bold.otf', assetResolver),
            Medium: new Asset('fonts/888-Medium.otf', assetResolver),
            Thin: new Asset('fonts/888-Thin.otf', assetResolver),
            DemiBold: new Asset('fonts/888-DemiBold.otf', assetResolver),
            Ultra: new Asset('fonts/888-Ultra.otf', assetResolver),
            Black: new Asset('fonts/888-Black.otf', assetResolver),
            Light: new Asset('fonts/888-Light.otf', assetResolver),
            Montserrat_Regular: new Asset('fonts/Montserrat-Regular.ttf', assetResolver),
            Montserrat_Bold: new Asset('fonts/Montserrat-Bold.ttf', assetResolver),
            Montserrat_Medium: new Asset('fonts/Montserrat-Medium.ttf', assetResolver),
            Montserrat_Thin: new Asset('fonts/Montserrat-Thin.ttf', assetResolver),
            Montserrat_DemiBold: new Asset('fonts/Montserrat-SemiBold.ttf', assetResolver),
            Montserrat_Ultra: new Asset('fonts/Montserrat-Ultra.ttf', assetResolver),
            Montserrat_Black: new Asset('fonts/Montserrat-Black.ttf', assetResolver),
            Montserrat_Light: new Asset('fonts/Montserrat-Light.ttf', assetResolver),
        },
        images: {
            avatar_help_center_2x_png: makeAsset('images/Avatar Help Center.png', assetResolver),
            bot_icon: makeAsset('images/Amanda@2x.png', assetResolver),
            agent_avatar: makeAsset('images/Live Agent Avatar 50x50px@2x.png', assetResolver),
            send_button_png: makeAsset('images/Send Button.png', assetResolver),
            send_button_2x_png: makeAsset('images/Send Button@2x.png', assetResolver),
            live_chat_icon_png: makeAsset('images/Live Chat Icon.png', assetResolver),
            live_chat_icon_2x_png: makeAsset('images/Live Chat Icon@2x.png', assetResolver),
            minimize_icon_png: makeAsset('images/Minimized Icon.png', assetResolver),
            minimize_icon_2x_png: makeAsset('images/Minimized Icon@2x.png', assetResolver),
            minimized_new_message_icon_png: makeAsset('images/Minimized New Message Icon Dublin.png', assetResolver), 
            minimized_new_message_icon_2x_png: makeAsset('images/Minimized New Message Icon Dublin@2x.png', assetResolver),
            typing_animation_gif: makeAsset('images/Typing-Animation.gif', assetResolver),
            close_button_png: makeAsset('images/X button.png', assetResolver),
            close_button_2x_png: makeAsset('images/X button@2x.png', assetResolver)
        }
    }
}

import { IMessageBroker } from "@sparkware/uc-sdk-core";
import { ApplicationService, IApplicationService } from "./application.service";
import { ChatBubbleService } from "./chat-bubble/chat-bubble.service";
import { IChatBubbleService } from "./chat-bubble/chat-bubble.service.interface";
import { IChatDataService } from "./chat-data/chat-data.service.interface";
import { ChatDataService } from "./chat-data/chat-data.service";
import { ChatStateService } from "./chat-data/chat-state.service";
import { IChatStateService } from "./chat-data/chat-state.service.interface";
import { LoadingIndictatorService } from "./chat-data/loading-indicator.service";
import { ClickStreamService, IClickStreamService } from "./clickstream-service";
import { HelpDataService } from "./help-widget/help-data.service";
import { IHelpDataService } from "./help-widget/help-data.service.interface";
import { ILanguageService, LanguageService } from "./language/language.service";
import { Lazy } from "./lazy";
import { ILoggerService, LoggerService } from "./logging.service";
import { IMessageBrokerService } from "./message-broker/message-broker.interface";
import { MessageBrokerService } from "./message-broker/message-broker.service";
import { IPlayerService, PlayerService } from "./player.service";
import { ResourcesService } from "./resources.service";
import { IServiceBase } from "./service-base.interface";
import { IServiceFactory } from "./service-factory.interface";
import { IThemeService, ThemeService } from "./theme.service";
import { IOsvcService } from "./osvc/osvc.service.interface";
import { OsvcService } from "./osvc/osvc.service";
import { IAuthService } from "./live-person/auth.service.interface";
import { AuthService } from "./live-person/auth.service";
import { ILivePersonService } from "./live-person/live-person.service.interface";
import { LivePersonService } from "./live-person/live-person.service";

export class ServiceFactory implements IServiceFactory {
  static loadedServices: IServiceBase[] = [];

  constructor(private readonly _messageBrokerChannels: IMessageBroker) {}

  dispose() {
    ServiceFactory.loadedServices.forEach((s) => s.dispose());
  }

  private _applicationService: Lazy<IApplicationService> =
    new Lazy<IApplicationService>(() => new ApplicationService(this));
  get applicationService(): IApplicationService {
    return this._applicationService.value;
  }

  private _messageBroker: Lazy<IMessageBrokerService> =
    new Lazy<IMessageBrokerService>(
      () => new MessageBrokerService(this._messageBrokerChannels, this)
    );
  get messageBroker(): IMessageBrokerService {
    return this._messageBroker.value;
  }

  private _chatBubble: Lazy<IChatBubbleService> = new Lazy<IChatBubbleService>(
    () => new ChatBubbleService(this)
  );
  get chatBubble(): IChatBubbleService {
    return this._chatBubble.value;
  }

  private _chatData: Lazy<IChatDataService> = new Lazy<IChatDataService>(
    () => new ChatDataService(this)
  );
  get chatData(): IChatDataService {
    return this._chatData.value;
  }

  private _theme: Lazy<IThemeService> = new Lazy<IThemeService>(
    () => new ThemeService(this)
  );
  get themes(): IThemeService {
    return this._theme.value;
  }

  private _logger: Lazy<ILoggerService> = new Lazy<ILoggerService>(
    () => new LoggerService(this)
  );
  get logger(): ILoggerService {
    return this._logger.value;
  }

  private _clickStream: Lazy<IClickStreamService> =
    new Lazy<IClickStreamService>(() => new ClickStreamService(this));
  get clickStream(): IClickStreamService {
    return this._clickStream.value;
  }

  private _loadingIndicator: Lazy<LoadingIndictatorService> =
    new Lazy<LoadingIndictatorService>(
      () => new LoadingIndictatorService(this)
    );
  get loadingIndicator(): LoadingIndictatorService {
    return this._loadingIndicator.value;
  }

  private _resourceService: Lazy<ResourcesService> = new Lazy<ResourcesService>(
    () => new ResourcesService(this)
  );
  get resourceService(): ResourcesService {
    return this._resourceService.value;
  }

  private _chatService: Lazy<IChatStateService> = new Lazy<IChatStateService>(
    () => new ChatStateService(this)
  );
  get chatService(): IChatStateService {
    return this._chatService.value;
  }

  private _playerService: Lazy<IPlayerService> = new Lazy<IPlayerService>(
    () => new PlayerService(this)
  );
  get playerService(): IPlayerService {
    return this._playerService.value;
  }

  private _helpCenterService: Lazy<IHelpDataService> =
    new Lazy<IHelpDataService>(() => new HelpDataService(this));
  get helpCenterService(): IHelpDataService {
    return this._helpCenterService.value;
  }

  private _languageService: Lazy<ILanguageService> = new Lazy<ILanguageService>(
    () => new LanguageService(this)
  );
  get languageService(): ILanguageService {
    return this._languageService.value;
  }

  private _osvcService: Lazy<IOsvcService> = new Lazy<IOsvcService>(
    () => new OsvcService(this)
  );
  get osvcService(): IOsvcService {
    return this._osvcService.value;
  }

  private _authService: Lazy<IAuthService> = new Lazy<IAuthService>(
    () => new AuthService(this)
  );
  get authService(): IAuthService {
    return this._authService.value;
  }

  private _livePersonService: Lazy<ILivePersonService> = new Lazy<ILivePersonService>(
    () => new LivePersonService(this)
  );
  get livePersonService(): ILivePersonService {
    return this._livePersonService.value;
  }
}

import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { DefaultMainScalePalette } from "../default/default-main-scale-palette";
import { defaultTheme } from "../default/default-theme";
import { Seven77MainScalePalette } from "./seven77-main-scale-palette";

export const seven77Theme = {
    colors: {
        primary: Seven77MainScalePalette.Primary,
        secondary: Seven77MainScalePalette.Secondary,
        text: Seven77MainScalePalette.Tertiary,
        secondaryText: Seven77MainScalePalette.Secondary,
        primaryHeader: Seven77MainScalePalette.Quinary,
        secondaryHeader: Seven77MainScalePalette.Quaternary,
        mainWindowBackground: Seven77MainScalePalette.Quaternary,
        helpCenterBackground: Seven77MainScalePalette.Quaternary,
        questionMarkBackground: Seven77MainScalePalette.Quaternary,
        urgentMessageBackground: Seven77MainScalePalette.Tertiary,
        urgentMessageTitleBarBackground: Seven77MainScalePalette.Quaternary,
        faqUrgentMessageBackground: Seven77MainScalePalette.Quinary,
        urgentMessageText: Seven77MainScalePalette.Secondary,
        messageBackground: Seven77MainScalePalette.Quinary,
        popupBackground: Seven77MainScalePalette.Quinary,
        userMessageBackground: Seven77MainScalePalette.Secondary,
        helpCenterButtonsBackground: Seven77MainScalePalette.Quinary,
        chatPageUrgentMessageBackground: Seven77MainScalePalette.Quinary,
        outOfHoursText: Seven77MainScalePalette.Secondary,
        outOfHoursBorder: Seven77MainScalePalette.Tertiary,
        outOfHoursBackground: Seven77MainScalePalette.Quinary,
        outOfHoursContainerText: Seven77MainScalePalette.Primary,
        outOfHoursContainerBackground: Seven77MainScalePalette.Quaternary,
        outOfHoursButtonText: Seven77MainScalePalette.Quinary,
        buttonHover: Seven77MainScalePalette.Secondary,
        primaryButtonText: Seven77MainScalePalette.Quinary,
        primaryButtonBackground: Seven77MainScalePalette.Primary,
        primaryButtonBackgroundHover: "#D37C90",
        secondaryButtonText: Seven77MainScalePalette.Tertiary,
        secondaryButtonBackground: Seven77MainScalePalette.Quaternary,
        confirmationDialogText: Seven77MainScalePalette.Secondary,
        headerButtonsColor: Seven77MainScalePalette.Secondary,
        chatTitle: Seven77MainScalePalette.Secondary,
        livePersonComposerTextBackgroundColor: Seven77MainScalePalette.Quinary,
        livePersonComposerText: Seven77MainScalePalette.Tertiary,
        livePersonChatBackgroundColor: Seven77MainScalePalette.Quaternary,
        livePersonAgentBackgroundMessageColor: Seven77MainScalePalette.Quinary,
        livePersonAgentMessageColor: Seven77MainScalePalette.Secondary,
        livePersonVisitorBackgroundMessageColor: Seven77MainScalePalette.Secondary,
        livePersonVisitorMessageColor: Seven77MainScalePalette.Quinary,
        livePersonTimestampColor: Seven77MainScalePalette.Tertiary,
        livePersonNotificationColor: Seven77MainScalePalette.Tertiary,
        livePersonInputText: Seven77MainScalePalette.Secondary,
        livePersonChipsColor : Seven77MainScalePalette.Primary,
        livePersonChipsHoverColor: DefaultGreyScalePalette.White,
        livePersonChipsBorderColor: Seven77MainScalePalette.Primary,
        livePersonSendButtonColor: Seven77MainScalePalette.Primary,
        iconColors: {
            default: Seven77MainScalePalette.Primary,
            disabledPrimary: Seven77MainScalePalette.Tertiary,
            disabledSecondary: Seven77MainScalePalette.Quaternary,
            hover: Seven77MainScalePalette.Secondary,
            openLinkIcon: DefaultGreyScalePalette.LightGrey,
            faqSpacingLine: DefaultGreyScalePalette.MediumDark,
            copyIcon: Seven77MainScalePalette.Tertiary
        } as IIconColors,
        helpCenterMainPageColors: {
            greetingMessage: Seven77MainScalePalette.Secondary,
            userName: Seven77MainScalePalette.Primary,
            faqTitle: Seven77MainScalePalette.Secondary,
        } as IHelpCenterMainPageColors,
    } as GeneralColors,
    images: {
        sendButton: 'Send button 777 Unpressed',
        liveChatIcon: 'Live Chat Icon 777 Dublin',
        minimizedIcon: 'Amanda 777 Bubble',
        minimizedNewMessageIcon: 'Amanda 777 Bubble Not',

        typingAnimation: "Typing-Animation 777",
        minimizeButton: "Minimize",
        closeButton: "Close",
        amandaSleeping: "AmandaHelpSleeping777",

        amandaBubble: "Amanda 777 Bubble",
        amandaBubbleWithNotification: "Amanda 777 Bubble Not",

        infoIcon: "info-icon-777",
        infoIconHover: "info-icon-777-hover"
    },
    textKeys: {
        ...defaultTheme.textKeys,   
        chatButtonLabel: "AllCaps_Chat_Button_Label",
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        hiMessage: ""
    },
    fontFamily: "'Montserrat'",
    fontWeight: {
        normal: '600',
        weights: {
            greetingMessage: '600',
            faqTitle: '600',
            faqQuestion: '400',
            outOfHoursCloseButton: '900',
            outOfHoursText: '500',
            outOfHoursHours: '700',
            outOfHoursCountry: '500',
            urgentMessageText: '700',
            urgentMessageCloseButton: '700',
            livePersonAgentMessage: '600'
        },
        opacity: {
            outOfHoursText: '0.8',
            versionNumber: '1'
        },
        padding: {
            outOfHoursHours: "10px"
        },
        border: {
            agentAvatar: "0"
        }
    }
};

import { AppIdentifiers, IComponentInitParams, IDivHostedAppInitParameters, IHelpCenterInitParameters } from "@sparkware/uc-sdk-core";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { RootComponent } from "../components/chat/chat-root.component";
import { HelpRoot } from "../components/help/help-root.component";
import { Help } from "../components/help/help.component";
import { ChatContextProvider } from "../services/contexts/chat-context";
import { ServiceFactory } from "../services/service-factory";
import { CHAT_APP_ID } from "../types/global";
import { getErrorMessage } from "../utils/error";
import { isNotSet } from "../utils/utility";
import {IHelpWidgetAppController} from "./help-widget.controller.interface";


export class HelpWidgetAppController implements IHelpWidgetAppController { 
    private _widgetContainerElement: HTMLElement | null = null;

    async init(appInitParams: IHelpCenterInitParameters): Promise<void> {
       
        console.log(`Locale: ${appInitParams.applicationContext.locale}; Language code: ${appInitParams.applicationContext.languageCode}`)
        
        this._widgetContainerElement = document.getElementById(appInitParams.applicationContext.launchInfo.containerID!);  
        const serviceFactory = new ServiceFactory(appInitParams.messageBrokerChannels)
        const {
          applicationService,
          logger,
          messageBroker
        } = serviceFactory;
      
        ReactDOM.render(
          <React.StrictMode>
            <ChatContextProvider value={{ services: serviceFactory }}>
              <HelpRoot/>
            </ChatContextProvider>
          </React.StrictMode>,
          this._widgetContainerElement
        );

        try {
          await serviceFactory.helpCenterService.init(appInitParams);
        }
        catch (ex) {
          const errorMessage = getErrorMessage(ex)
          logger.error(`Init failed ${errorMessage}`)
          messageBroker.initFailed(AppIdentifiers.CRHelpCenter, {
            errorCode: "1",
            errorDescription: errorMessage
          })
          return;
        }
        
        applicationService.appClosedCallback = (payLoad) => {
          if(payLoad.appID != AppIdentifiers.CRHelpCenter) return;

          if(!isNotSet(this._widgetContainerElement)){
            ReactDOM.unmountComponentAtNode( this._widgetContainerElement!);
            this._widgetContainerElement = null;
          }
        }
    }
}

function ApplicationID(ApplicationID: any, arg1: { errorCode: number; errorDescription: string; }) {
  throw new Error("Function not implemented.");
}

import { IHelpCenterMainPageColors } from "../../architecture/interface/help-center-main-page-colors.interface";
import { IIconColors } from "../../architecture/interface/icon-colors.interface";
import { GeneralColors } from "../../architecture/widget-theme/class/general-colors";
import { DefaultGreyScalePalette } from "../default/default-greyscale-palette";
import { defaultTheme } from "../default/default-theme";
import { MiCasinoMainScalePalette } from "./mi-casino-main-scale-palette";

export const MiCasinoTheme = {
    colors: {
        ...defaultTheme.colors,
        primary: MiCasinoMainScalePalette.Primary,
        secondary: DefaultGreyScalePalette.White,
        primaryHeader: DefaultGreyScalePalette.Dark,
        outOfHoursContainerText: MiCasinoMainScalePalette.Primary,
        primaryButtonText: DefaultGreyScalePalette.White,
        primaryButtonBackground: MiCasinoMainScalePalette.Primary,
        primaryButtonBackgroundHover: "#EC6759",
        iconColors: {
            ...defaultTheme.colors.iconColors,
        } as IIconColors,
        helpCenterMainPageColors: {
            ...defaultTheme.colors.helpCenterMainPageColors,
            userName: MiCasinoMainScalePalette.Primary,
        } as IHelpCenterMainPageColors
    } as GeneralColors,
    images: {
        ...defaultTheme.images,
        amandaBubble: "Amanda SI Bubble 55x55px",
        amandaBubbleWithNotification: "Amanda SI Bubble Not 55x55px"
    },
    textKeys: {
        ...defaultTheme.textKeys,   
        faqButtonLabel: "AllCaps_Faq_Button_Label",
        outOfHoursCloseLabel: "AllCaps_OutOfHours_Close_Label",
        chatButtonLabel: "Chat_Button_Label",
        noLabel: "AllCaps_No_Label",
        yesLabel: "AllCaps_Yes_Label"
    },
};

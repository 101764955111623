
import React from 'react';
import styled from "styled-components";
import { useChatContext } from "../../services/contexts/chat-context";


const UrgentMessagePopupContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const UrgentMessagePopupOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.62) 0% 0% no-repeat padding-box;
    z-index: 20000;
`;

const UrgentMessageWindow = styled.div`
    margin-top: 125px;
    margin-left: 5%;
    width: 90%;
    height: 412px;
    background: ${(props: any) => props.theme.colors.popupBackground} 0% 0% no-repeat padding-box;
    border: 2px solid #717171;
    border-radius: 10px;
    z-index: 20001;
    opacity: 1;
    position: absolute;
`;

const TitleBar = styled.div`
    width: 100%;
    height: 49px;
    background: ${(props: any) => props.theme.colors.urgentMessageTitleBarBackground};
    border-radius: 9px 9px 0px 0px;
    opacity: 1;
`;

const Title = styled.div`
    height: 100%;
    padding-top: 15px;
    width: 100%;
    text-align: center;
    font: normal normal bold 14px/21px 888;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.urgentMessageCloseButton} !important;
    font-size: 14px;
    letter-spacing: 0.98px;
    color: ${(props: any) => props.theme.colors.secondary};
    text-transform: capitalize;
    opacity: 1;
`;

const Icon = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 76px;
    width: 76px;
    margin-top: 20px;
`;

const CloseButton = styled.button`
    position: absolute;
    bottom: 30px;
    width: 90%;
    height: 43px;
    margin-left: 5%;
    margin-right: 5%;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.urgentMessageCloseButton} !important;
    font-size: 14px;
    border-radius: 20px;
    background-color: ${(props: any) => props.theme.colors.primaryButtonBackground};
    border: 2px solid;
    border-color: ${(props: any) => props.theme.colors.primaryButtonBackground};
    cursor: pointer;
    color: ${(props: any) => props.theme.colors.primaryButtonText};

    &:hover, &:active{
        background: ${(props: any) => props.theme.colors.primaryButtonBackgroundHover}; 0% 0% no-repeat padding-box;
        border: 2px solid ${(props: any) => props.theme.colors.primaryButtonBackgroundHover};
        color:  ${(props: any) => props.theme.colors.primaryButtonText};

`;

const UrgentMessageText = styled.div`
    width: 90%;
    height: 155px;
    overflow: auto;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
    text-align: center;
    font: normal normal 600 14px/19px 888;
    letter-spacing: 0.98px;
    font-family: ${(props: any) => props.theme.fontFamily} !important;
    font-weight: ${(props: any) => props.theme.fontWeight.weights.urgentMessageText} !important;
    font-size: 14px;
    color: ${(props: any) => props.theme.colors.urgentMessageText};
    opacity: 1;

    > a {
        color: color: ${(props: any) => props.theme.colors.urgentMessageText};
        text-decoration: underline;

        &:hover, &:active{
            color: color: ${(props: any) => props.theme.colors.urgentMessageText};
        }
        
        &:visited {
            color: color: ${(props: any) => props.theme.colors.urgentMessageText};
        }
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        background-color: #232323;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
        background-color: #232323;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3C3C3C;
    }
`;

export const UrgentMessagePopup: React.FC<any> = (props: {onClose : () => void}) => {
    const {
        themes,
        languageService,
        chatData,
        resourceService
    } = useChatContext().services;

    return (
        <UrgentMessagePopupContainer>
            <UrgentMessagePopupOverlay/>
            <UrgentMessageWindow>
                <TitleBar>
                    <Title id="popupTitle">{languageService.translate("ImportantMessage_Label")}</Title>
                </TitleBar>
                <Icon id="urgentMessageicon" src={themes.currentTheme.assets.images.live_chat_icon_2x_png}/>
                <UrgentMessageText id ="urgentMessageText" dangerouslySetInnerHTML={{ __html: (chatData.chatHours?.urgentMessageText ? chatData.chatHours?.urgentMessageText : "") }}></UrgentMessageText>
                <CloseButton id ="urgentMessageCloseButton" onClick={() => {props.onClose()}}>{languageService.translate(themes.currentTheme.textKeys.outOfHoursCloseLabel)}</CloseButton>
            </UrgentMessageWindow>
        </UrgentMessagePopupContainer>
    )
}
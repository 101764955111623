import { IAssetResolver } from "../asset-resolver.interface";
import { IAsset } from "./asset.interface";

export class Asset implements IAsset {

    constructor(private readonly _relativePath: string,
                private readonly _assetResolver: IAssetResolver){              
                }

    toString() {
        return  this._assetResolver.resolveAssetPath(this._relativePath, [])
    }
}